import { analytics } from "@flare/analytics";

import type { FunnelAnswers } from "@/modules/v2/funnel/config";
import { BiEventsNames } from "@/services/analytics/event-names";

import {
  checkIntakeOfficeAvailability,
  CLOSE_HOUR,
  OPEN_HOUR,
} from "./checkIntakeOfficeAvailability";

export function checkCmnEligibility({ answers }: { answers: FunnelAnswers }) {
  const isInBusinessHours = checkIntakeOfficeAvailability();
  const isEligible = answers.leadIntent === "medium" && isInBusinessHours;

  analytics.track(BiEventsNames.WebCmnEligibility, {
    is_eligible: isEligible,
    lead_intent: answers.leadIntent,
    in_business_hours: isInBusinessHours,
    lead_score: answers.leadScore,
    business_hour_open: OPEN_HOUR,
    business_hour_close: CLOSE_HOUR,
  });

  answers.isEligibleForCmn = isEligible;

  return isEligible;
}
