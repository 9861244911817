import { captureException } from "@sentry/nextjs";

export interface HTTPError extends Error {
  reason?: string;
  status?: number;
  title?: string;
}

type RequestHeaders =
  | NonNullable<RequestInit["headers"]>
  | Record<string, string | undefined>;

export interface RequestOptions extends Omit<RequestInit, "body" | "headers"> {
  body?: unknown;
  headers?: RequestHeaders;
}

export async function jsonRequest<T>(
  endpoint: string = "",
  options: RequestOptions = {},
) {
  const defaultHeaders = {
    "Content-Type": "application/json",
  };

  const config = {
    method: "GET",
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers,
    },
    // Automatically stringify the body
    body: options.body ? JSON.stringify(options.body) : undefined,
  };

  try {
    const response = await fetch(endpoint, config);

    // Check for non-2XX status codes.
    // Checkout https://kettanaito.com/blog/why-fetch-promise-doesnt-reject-on-error-responses for more info.
    if (!response.ok) {
      const responseText = await response.text();
      const title = responseText ?? response.statusText;
      const status = `${response.status} ${title}`.trim();
      const reason = status ? `status code ${status}` : "an unknown error";

      const error: HTTPError = new Error(
        `The server responded with ${reason}.`,
      );

      error.title = title;
      error.status = response.status;
      error.reason = reason;

      throw error;
    }

    const result = response.headers
      .get("content-type")
      ?.includes("application/json")
      ? await response.json()
      : await response.text();

    return result as T;
  } catch (err) {
    captureException(err, {
      extra: {
        message: `jsonRequest error: error fetching data from ${endpoint}`,
      },
    });

    // Handle network-related errors or other unexpected errors
    if (err instanceof TypeError) {
      const error: HTTPError = new Error(
        "Network error or request was aborted.",
      );
      error.reason = "Network error";
      error.status = 0;
      throw error;
    }

    // Re-throw other errors (e.g., HTTP errors)
    throw err;
  }
}
