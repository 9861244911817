import { captureException } from "@sentry/nextjs";
import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useSessionStorage } from "usehooks-ts";

import { analytics } from "@flare/analytics";
import { getSession } from "@flare/next-auth/react";

import { getCompetencies } from "@/components/Funnel/utils/get-competencies";
import { getFips } from "@/components/Funnel/utils/get-fips";
import type {
  BookitCreateRequest,
  BookitCreateRequestResponse,
  PracticeArea,
} from "@/edge/contract";
import { PracticeAreaName } from "@/edge/contract";
import { jsonRequest } from "@/lib/network/json-request";
import type { FunnelAnswers } from "@/modules/v2/funnel/config";
import {
  isQualifiedInternalEmail,
  testCounty,
  testStateCode,
} from "@/modules/v2/funnel/visitor-checks/checkQualifiedInternalEmail";
import { BiEventsNames } from "@/services/analytics/event-names";

export declare type CreateBookitCreateRequest = {
  practice: string;
  request: BookitCreateRequest;
  step: string;
};

export function useBookitRequest() {
  const { query } = useRouter();
  const [, setBookitRequestId] = useSessionStorage<string | null>(
    "bookitRequestId",
    null,
  );

  const createBookitRequestMutation = useMutation({
    mutationFn: async (input: CreateBookitCreateRequest) => {
      return jsonRequest<BookitCreateRequestResponse>(
        "/api/vinny/bookee/createRequest",
        {
          method: "POST",
          body: input.request,
        },
      );
    },
    onError: (error, { step, practice }) => {
      analytics.track(BiEventsNames.WebFunnelError, {
        current_step_key: step,
        error_type: "create_bookit_request",
        error_message: error.message,
        practice,
      });

      captureException(error, {
        extra: {
          action: "create_bookit_request",
        },
      });
    },
    onSuccess(data) {
      const newRequestId = data.id || null;
      setBookitRequestId(newRequestId);
    },
  });

  const createBookitRequest = async (leadId: string) => {
    const { context } = (await getSession()) ?? {};

    const {
      subPractice,
      serviceType,
      sponsorType,
      email,
      firstName,
      lastName,
      practice,
      stateCode,
      county,
    } = (context?.funnelData as FunnelAnswers) || {};

    const practiceArea: PracticeArea =
      practice?.toUpperCase() === PracticeAreaName.Immigration
        ? "Immigration"
        : "Family";

    const [actualStateCode, actualCounty] = isQualifiedInternalEmail(
      email,
      "dlss",
    )
      ? [testStateCode, testCounty]
      : [stateCode, county];

    const competencies = getCompetencies({
      practice: practiceArea,
      subPractice,
      serviceType,
      sponsorType,
    });

    const fips = getFips({
      county: actualCounty,
      stateCode: actualStateCode,
    });

    const bookitRequestPayload: BookitCreateRequest = {
      practiceArea,
      meetingType: "LSS",
      customerEmail: email ?? "",
      customerFirstName: firstName ?? "",
      customerLastName: lastName ?? "",
      competencies,
      metadata: {
        salesforce_id: leadId!,
        source: "funnel",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    if (practiceArea === "Family") {
      bookitRequestPayload.stateCode = actualStateCode;
      bookitRequestPayload.fips = fips;
    }
    createBookitRequestMutation.mutate({
      request: bookitRequestPayload,
      step: query.step as string,
      practice: practice!,
    });
  };

  return { createBookitRequest };
}
