import type { ReactNode } from "react";

import { signIn, useSession } from "@flare/next-auth/react";

import { useAsyncEffect } from "@/hooks/useAsyncEffect";

/**
 * AnonymousSession is a wrapper component that ensures the user is authenticated.
 *
 * If the user is not authenticated, it will attempt to sign in as an anonymous user.
 */
export function AnonymousSession({ children }: { children: ReactNode }) {
  const { data: session, status } = useSession();

  useAsyncEffect(async () => {
    if (status === "unauthenticated" || session?.status == "error") {
      await signIn("anonymous", { redirect: false });
    }
  }, [status, session?.status]);

  return children;
}
