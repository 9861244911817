import { useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { motion } from "framer-motion";

import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";

type IntakeConfirmationInterludeProps = {
  heading: string;
  subHeading?: string;
};

export function IntakeConfirmationInterlude({
  heading,
  subHeading,
}: IntakeConfirmationInterludeProps) {
  useEffect(() => {
    // Scroll to the top of the page to ensure not sticky top nav is visible and completing overlay to 100% height.
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: 0,
      transition: {
        duration: 0.25,
        ease: "easeOut",
      },
    },
    exit: {
      y: "100%",
      transition: {
        duration: 0.25,
        ease: "easeIn",
      },
    },
  };

  return (
    <Dialog.Root open>
      <Dialog.Portal container={document.getElementById("appContainer")}>
        <Dialog.Overlay />
        <motion.div
          className="fixed bottom-0 left-0 z-10 flex h-[calc(100%-65px)] w-screen flex-col items-center gap-4 bg-white-80 focus-within:outline-none md:h-[calc(100%-73px)]"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="absolute top-[20%] flex h-full flex-col items-center">
            <div className="w-5/12 md:w-1/5">
              <video className="w-full" autoPlay muted playsInline>
                <source
                  src="/next/videos/intake-confirmation-interlude.webm"
                  type="video/webm"
                />
              </video>
            </div>
            <div className="flex flex-col items-center justify-center gap-1">
              <Dialog.Title asChild>
                <Heading variant="h4">{heading}</Heading>
              </Dialog.Title>
              {subHeading && (
                <Dialog.Description asChild>
                  <Text variant="text-3">{subHeading}</Text>
                </Dialog.Description>
              )}
            </div>
          </div>
        </motion.div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
