import * as s from "react";
import { u as V, c as p } from "./index-BlaUKar8.js";
import { c as j, u as z } from "./index-eNC7-srW.js";
import { u as q } from "./index-CCavb-1K.js";
import { c as J, u as Q } from "./index-CVQolMUX.js";
import { u as W } from "./index-BosAEyXu.js";
import { P as x } from "./index-u2zzFwMH.js";
import { jsx as d } from "react/jsx-runtime";
var h = "rovingFocusGroup.onEntryFocus", X = { bubbles: !1, cancelable: !0 }, b = "RovingFocusGroup", [y, G, Z] = j(b), [$, ve] = J(
  b,
  [Z]
), [ee, oe] = $(b), N = s.forwardRef(
  (e, r) => /* @__PURE__ */ d(y.Provider, { scope: e.__scopeRovingFocusGroup, children: /* @__PURE__ */ d(y.Slot, { scope: e.__scopeRovingFocusGroup, children: /* @__PURE__ */ d(te, { ...e, ref: r }) }) })
);
N.displayName = b;
var te = s.forwardRef((e, r) => {
  const {
    __scopeRovingFocusGroup: c,
    orientation: o,
    loop: F = !1,
    dir: g,
    currentTabStopId: R,
    defaultCurrentTabStopId: E,
    onCurrentTabStopIdChange: m,
    onEntryFocus: a,
    preventScrollOnEntryFocus: w = !1,
    ...C
  } = e, v = s.useRef(null), I = q(r, v), t = z(g), [l = null, T] = V({
    prop: R,
    defaultProp: E,
    onChange: m
  }), [u, i] = s.useState(!1), S = Q(a), k = G(c), A = s.useRef(!1), [L, P] = s.useState(0);
  return s.useEffect(() => {
    const n = v.current;
    if (n)
      return n.addEventListener(h, S), () => n.removeEventListener(h, S);
  }, [S]), /* @__PURE__ */ d(
    ee,
    {
      scope: c,
      orientation: o,
      dir: t,
      loop: F,
      currentTabStopId: l,
      onItemFocus: s.useCallback(
        (n) => T(n),
        [T]
      ),
      onItemShiftTab: s.useCallback(() => i(!0), []),
      onFocusableItemAdd: s.useCallback(
        () => P((n) => n + 1),
        []
      ),
      onFocusableItemRemove: s.useCallback(
        () => P((n) => n - 1),
        []
      ),
      children: /* @__PURE__ */ d(
        x.div,
        {
          tabIndex: u || L === 0 ? -1 : 0,
          "data-orientation": o,
          ...C,
          ref: I,
          style: { outline: "none", ...e.style },
          onMouseDown: p(e.onMouseDown, () => {
            A.current = !0;
          }),
          onFocus: p(e.onFocus, (n) => {
            const U = !A.current;
            if (n.target === n.currentTarget && U && !u) {
              const D = new CustomEvent(h, X);
              if (n.currentTarget.dispatchEvent(D), !D.defaultPrevented) {
                const _ = k().filter((f) => f.focusable), B = _.find((f) => f.active), Y = _.find((f) => f.id === l), H = [B, Y, ..._].filter(
                  Boolean
                ).map((f) => f.ref.current);
                M(H, w);
              }
            }
            A.current = !1;
          }),
          onBlur: p(e.onBlur, () => i(!1))
        }
      )
    }
  );
}), O = "RovingFocusGroupItem", K = s.forwardRef(
  (e, r) => {
    const {
      __scopeRovingFocusGroup: c,
      focusable: o = !0,
      active: F = !1,
      tabStopId: g,
      ...R
    } = e, E = W(), m = g || E, a = oe(O, c), w = a.currentTabStopId === m, C = G(c), { onFocusableItemAdd: v, onFocusableItemRemove: I } = a;
    return s.useEffect(() => {
      if (o)
        return v(), () => I();
    }, [o, v, I]), /* @__PURE__ */ d(
      y.ItemSlot,
      {
        scope: c,
        id: m,
        focusable: o,
        active: F,
        children: /* @__PURE__ */ d(
          x.span,
          {
            tabIndex: w ? 0 : -1,
            "data-orientation": a.orientation,
            ...R,
            ref: r,
            onMouseDown: p(e.onMouseDown, (t) => {
              o ? a.onItemFocus(m) : t.preventDefault();
            }),
            onFocus: p(e.onFocus, () => a.onItemFocus(m)),
            onKeyDown: p(e.onKeyDown, (t) => {
              if (t.key === "Tab" && t.shiftKey) {
                a.onItemShiftTab();
                return;
              }
              if (t.target !== t.currentTarget) return;
              const l = se(t, a.orientation, a.dir);
              if (l !== void 0) {
                if (t.metaKey || t.ctrlKey || t.altKey || t.shiftKey) return;
                t.preventDefault();
                let u = C().filter((i) => i.focusable).map((i) => i.ref.current);
                if (l === "last") u.reverse();
                else if (l === "prev" || l === "next") {
                  l === "prev" && u.reverse();
                  const i = u.indexOf(t.currentTarget);
                  u = a.loop ? ce(u, i + 1) : u.slice(i + 1);
                }
                setTimeout(() => M(u));
              }
            })
          }
        )
      }
    );
  }
);
K.displayName = O;
var re = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function ne(e, r) {
  return r !== "rtl" ? e : e === "ArrowLeft" ? "ArrowRight" : e === "ArrowRight" ? "ArrowLeft" : e;
}
function se(e, r, c) {
  const o = ne(e.key, c);
  if (!(r === "vertical" && ["ArrowLeft", "ArrowRight"].includes(o)) && !(r === "horizontal" && ["ArrowUp", "ArrowDown"].includes(o)))
    return re[o];
}
function M(e, r = !1) {
  const c = document.activeElement;
  for (const o of e)
    if (o === c || (o.focus({ preventScroll: r }), document.activeElement !== c)) return;
}
function ce(e, r) {
  return e.map((c, o) => e[(r + o) % e.length]);
}
var Ie = N, be = K;
export {
  be as I,
  Ie as R,
  ve as c
};
