import { useQuery } from "@tanstack/react-query";

import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { useUpdateLead } from "@/modules/v2/funnel/hooks/useUpdateLead";

export function useLeadId() {
  const { updateLead } = useUpdateLead();
  const { funnelAnswers } = useFunnelAnswers();
  const { funnelActionId } = funnelAnswers || {};
  return useQuery({
    queryKey: [funnelActionId],
    queryFn: async () => {
      const resp = await updateLead();
      return resp?.user.integrationInfo?.sfLeadId;
    },
  });
}
