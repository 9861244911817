import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useBookit, useBookSlot } from "@marbletech/bookit-core";
import { captureException } from "@sentry/nextjs";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import type { StripeError } from "@stripe/stripe-js";
import { isEqual } from "date-fns";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { analytics } from "@flare/analytics";
import { useTranslation } from "@flare/i18n";

import bannerImg from "@/../public/next/images/checkout_form_banner.png";
import { ShieldIcon } from "@/components/icons/ShieldIcon";
import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";
import { PracticeAreaName } from "@/edge/contract";
import { useAddPaymentMutation } from "@/generated/graphql.old_backend";
import { ActionButton } from "@/modules/v2/funnel/components/action-button";
import { SkipButton } from "@/modules/v2/funnel/components/skip-button";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { useFunnelMeta } from "@/modules/v2/funnel/hooks/useFunnelMeta";
import { Interlude } from "@/modules/v2/funnel/Interlude";
import type { StepHandlers } from "@/modules/v2/funnel/steps/step.types";
import { BiEventsNames } from "@/services/analytics/event-names";

import { meetingType } from "../funnel.consts";
import { TextField } from "../TextField";

import type { LssPricesKeys } from "./LssCheckout.consts";
import {
  CARD_CVC,
  CARD_EXPIRY,
  CARD_NUMBER,
  CARD_OWNER,
  CARD_ZIP_CODE,
  lssPrices,
} from "./LssCheckout.consts";
import { PriceInfoCard } from "./PriceInfoCard";
import { StripeComponent } from "./StripeComponent";
import { WalletPay } from "./WalletPay";

export function CheckoutForm({
  clientSecret,
  leadId,
  handlers,
}: {
  clientSecret: string;
  handlers: StepHandlers;
  leadId: string;
}) {
  const { t } = useTranslation();
  const [addPaymentMethodMutation] = useAddPaymentMutation();
  const { mutateAsync: bookSlot } = useBookSlot();
  const { meeting } = useBookit();
  const stripe = useStripe();
  const elements = useElements();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { onSkip } = handlers;

  const {
    unregister,
    watch,
    trigger,
    formState: { isValid },
  } = useFormContext();
  const router = useRouter();
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const { funnelAnswers } = useFunnelAnswers();
  const practice = router.query.practice as string;
  const step = router.query.step as string;

  const { getFunnelMeta, setFunnelMeta } = useFunnelMeta();
  const { funnelActionId, lssStartTime, eventStartTime } = funnelAnswers;

  function triggerExternalFormSubmit() {
    // Unregister Stripe fields to remove them from form values
    unregister([CARD_NUMBER, CARD_EXPIRY, CARD_CVC, CARD_ZIP_CODE, CARD_OWNER]);
    // Trigger form onSubmit in [step].tsx
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  }

  async function handlePayment(e: React.MouseEvent) {
    // Prevent form submission in [step].tsx
    e.preventDefault();

    if (!isValid) {
      trigger();
      return;
    }

    analytics.track(BiEventsNames.WebFunnelPaymentClick, {
      value: "submit",
      type: "FUNNEL_CHECKOUT_FORM",
      payment_type: meetingType.lss,
      sf_funnel_action_id: funnelActionId,
    });

    setIsSubmittingPayment(true);

    if (!stripe || !elements) {
      console.error("Stripe has not been properly initialized");
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (!card) {
      console.error("Card information is not available");
      return;
    }

    if (!lssStartTime) {
      console.error("LSS start time is not available");
      return;
    }

    const funnelMeta = getFunnelMeta();

    let confirmCardSetup;

    // Setting up a payment method with the intent to charge it later
    try {
      confirmCardSetup = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            name: watch(CARD_OWNER),
            address: {
              postal_code: watch(CARD_ZIP_CODE),
            },
          },
        },
      });

      if (confirmCardSetup.error) {
        throw confirmCardSetup.error;
      }
    } catch (error) {
      const stripeError = error as StripeError;
      analytics.track(BiEventsNames.WebFunnelPaymentDeclined, {
        current_step_key: step,
        practice,
        ...stripeError,
      });

      captureException(error, {
        extra: {
          action: "confirmCardSetup",
        },
      });

      setFunnelMeta({
        ...funnelMeta,
        checkoutStatus: "payment-declined",
      });

      triggerExternalFormSubmit();

      return;
    }

    const slot = {
      start: new Date(lssStartTime),
    };
    // If the selected slot is already booked for the user, skip the booking
    if (isEqual(slot.start, new Date(meeting?.dateTime || ""))) {
      analytics.track(BiEventsNames.WebCalendarSkip, {
        slot: slot.start,
        meeting_type: meetingType.lss,
        sf_funnel_action_id: funnelActionId,
      });
    } else {
      // Execute bookit submit meeting
      try {
        await bookSlot(slot);

        analytics.track(BiEventsNames.WebCalendarSuccess, {
          slot: slot.start,
          meeting_type: meetingType.lss,
          email_hash: funnelMeta?.hashedEmail,
          phone_hash: funnelMeta?.hashedPhone,
          email_hash_unsalted: funnelMeta?.unsaltedSanitizedEmail,
          phone_hash_unsalted: funnelMeta?.unsaltedSanitizedPhone,
          sf_funnel_action_id: funnelActionId,
        });
      } catch (error) {
        analytics.track(BiEventsNames.WebFunnelError, {
          current_step_key: step,
          error_type: "book_slot",
          error_message:
            error instanceof Error ? error.message : "bookSlot unknown error",
          practice,
        });

        captureException(error, {
          extra: {
            action: "bookSlot",
          },
        });

        setFunnelMeta({
          ...funnelMeta,
          checkoutStatus: "slot-is-taken",
        });

        triggerExternalFormSubmit();

        return;
      }
    }

    // Execute the payment
    try {
      await addPaymentMethodMutation({
        variables: {
          input: {
            opportunityId: leadId,
            setupIntentId: confirmCardSetup.setupIntent.id,
          },
        },
      });
    } catch (error) {
      analytics.track(BiEventsNames.WebFunnelError, {
        current_step_key: step,
        error_type: "add_payment",
        error_message:
          error instanceof Error ? error.message : "addPayment unknown error",
        practice,
      });

      captureException(error, {
        extra: {
          action: "addPayment",
          component: "CheckoutForm",
        },
      });

      setFunnelMeta({
        ...funnelMeta,
        checkoutStatus: "payment-declined",
      });

      triggerExternalFormSubmit();

      return;
    }

    // Payment successful
    analytics.track(BiEventsNames.WebFunnelPaymentSuccess, {
      payment_type: meetingType.lss,
      sf_funnel_action_id: funnelActionId,
    });
    setFunnelMeta({
      ...funnelMeta,
      checkoutStatus: "lss-confirmation",
    });

    triggerExternalFormSubmit();
  }

  // Show intake banner only if an intake call has not already been scheduled
  const IntakeCallBanner = !eventStartTime && (
    <div className="-mx-5 flex items-center justify-between bg-neutral-50 p-6 md:rounded-2xl md:p-8">
      <div className="w-7/12 md:w-8/12 ">
        <Text variant="text-4" className="mb-2 font-medium md:text-xl">
          {t("FUNNEL_CHECKOUT_FORM_INTAKE_BANNER_TITLE")}
        </Text>
        <Text variant="text-5" className="mb-4 md:mb-6 md:text-base">
          {t("FUNNEL_CHECKOUT_FORM_INTAKE_BANNER_DESCRIPTION")}
        </Text>
        <SkipButton
          variant="outline"
          onSkip={onSkip}
          className="h-9 min-w-0 rounded-lg px-4 text-sm md:h-12 md:px-6 md:text-base"
        >
          {t("FUNNEL_CHECKOUT_FORM_INTAKE_BANNER_CTA")}
        </SkipButton>
      </div>
      <div className="flex w-5/12 items-center justify-end md:w-4/12">
        <Image
          src={bannerImg}
          className="h-min w-[147px]"
          alt={t("FUNNEL_CHECKOUT_FORM_INTAKE_OFFER_IMAGE_ALT")}
        />
      </div>
    </div>
  );

  let priceInfoFirstBulletText = t(
    "FUNNEL_CHECKOUT_FORM_PRICE_INFO_BULLET_2_FAMILY",
  );
  if (
    practice?.toUpperCase() === PracticeAreaName.Family &&
    funnelAnswers.subPractice
  ) {
    priceInfoFirstBulletText = t(
      "FUNNEL_CHECKOUT_FORM_PRICE_INFO_BULLET_2_IMMIGRATION",
      { subPractice: funnelAnswers.subPractice.replace("-", " ") },
    );
  }

  return (
    <>
      {isSubmittingPayment && <Interlude heading="Confirming your payment" />}
      <div className="flex flex-col gap-7">
        <Heading variant="h4" className="text-center font-normal">
          {t("FUNNEL_CHECKOUT_FORM_TITLE")}
        </Heading>
        <PriceInfoCard
          nextStepsText={priceInfoFirstBulletText}
          price={lssPrices[practice.toUpperCase() as LssPricesKeys]}
        />
        <div className="flex flex-col gap-5">
          <WalletPay
            clientSecret={clientSecret}
            leadId={leadId}
            triggerExternalFormSubmit={triggerExternalFormSubmit}
            setIsSubmittingPayment={setIsSubmittingPayment}
          />
          <Heading variant="h5" className="font-sans font-medium">
            {t("FUNNEL_CHECKOUT_FORM_PAYMENT_TITLE")}
          </Heading>
          <TextField
            name={CARD_OWNER}
            label={t("FUNNEL_CHECKOUT_FORM_NAME_LABEL")}
            placeholder={t("FUNNEL_CHECKOUT_FORM_NAME_PLACEHOLDER")}
            validationErrorMessage={t("FUNNEL_CHECKOUT_FORM_REQUIRED_FIELD")}
            type="text"
            onChange={() => {
              trigger(CARD_OWNER);
            }}
            required
          />
          <StripeComponent
            component={CardNumberElement}
            name={CARD_NUMBER}
            label={t("FUNNEL_CHECKOUT_FORM_CARD_NUMBER_LABEL")}
            placeholder={t("FUNNEL_CHECKOUT_FORM_CARD_NUMBER_PLACEHOLDER")}
            onChange={() => {
              trigger(CARD_NUMBER);
            }}
          />
          <div className="flex flex-col gap-5 md:flex-row md:justify-between md:gap-3">
            <StripeComponent
              component={CardExpiryElement}
              name={CARD_EXPIRY}
              label={t("FUNNEL_CHECKOUT_FORM_EXPIRATION_DATE_LABEL")}
              placeholder={t(
                "FUNNEL_CHECKOUT_FORM_EXPIRATION_DATE_PLACEHOLDER",
              )}
              onChange={() => {
                trigger(CARD_EXPIRY);
              }}
            />
            <StripeComponent
              component={CardCvcElement}
              name={CARD_CVC}
              label={t("FUNNEL_CHECKOUT_FORM_CVC_LABEL")}
              placeholder={t("FUNNEL_CHECKOUT_FORM_CVC_PLACEHOLDER")}
              onChange={() => {
                trigger(CARD_CVC);
              }}
            />
          </div>
          <TextField
            name={CARD_ZIP_CODE}
            label={t("FUNNEL_CHECKOUT_FORM_ZIP_CODE_LABEL")}
            placeholder={t("FUNNEL_CHECKOUT_FORM_ZIP_CODE_PLACEHOLDER")}
            type="zipCode"
            validationErrorMessage={t(
              "FUNNEL_CHECKOUT_FORM_ZIP_CODE_ERROR_MESSAGE",
            )}
            maxLength={5}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
              trigger(CARD_ZIP_CODE);
            }}
            required
          />
        </div>
        <div className="flex items-center gap-2">
          <ShieldIcon height={18} width={16} />
          <Text className="font-medium" variant="text-6">
            {t("FUNNEL_CHECKOUT_FORM_SSL_ENCRYPTED")}
          </Text>
        </div>
        <Text variant="text-6" className="text-center text-blue-40">
          {t("FUNNEL_CHECKOUT_FORM_AUTHORIZATION_TEXT_1")}{" "}
          <Link target="_blank" href="/privacy" className="text-green-100">
            {t("PRIVACY_POLICY")} &{" "}
          </Link>
          <Link target="_blank" href="/terms-of-use" className="text-green-100">
            {t("TERMS_OF_USE")}.{" "}
          </Link>
          {t("FUNNEL_CHECKOUT_FORM_AUTHORIZATION_TEXT_2")}
        </Text>
        {IntakeCallBanner}
        <ActionButton
          type="button"
          onClick={handlePayment}
          disabled={isSubmittingPayment}
        >
          {t("FUNNEL_CHECKOUT_FORM_CTA_TEXT")}
        </ActionButton>
        <button ref={submitButtonRef} type="submit" className="hidden" hidden />
      </div>
    </>
  );
}
