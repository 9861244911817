import { useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";

import { signIn, useSession } from "@flare/next-auth/react";

import { useAsyncEffect } from "@/hooks/useAsyncEffect";

import type { FunnelAnswers } from "../config";

const MAX_UPDATE_FAILURES_COUNT = 3;

/**
 * Hook to manage the funnel answers in the session context and local state.
 */
export function useFunnelAnswers() {
  const { status, data: session, update: updateSession } = useSession();
  const [allowLogin, setLogin] = useState(true);
  const [failuresCount, setFailuresCount] = useState(0);
  const [funnelAnswers, setFunnelAnswers] = useState<FunnelAnswers>(
    session?.context?.funnelData ?? {},
  );

  useAsyncEffect(async () => {
    if (isEmpty(funnelAnswers)) return;

    if (!isEqual(session?.context?.funnelData, funnelAnswers)) {
      const sess = await updateSession({ funnelData: funnelAnswers });
      if (sess) {
        setLogin(true);
      } else if (failuresCount < MAX_UPDATE_FAILURES_COUNT) {
        setFailuresCount(failuresCount + 1);
      } else {
        setFailuresCount(0);
        if (allowLogin) {
          setLogin(false);
          if (session?.provider === "anonymous") {
            await signIn(
              "anonymous",
              { redirect: false },
              { __session: JSON.stringify(session) },
            );
          }
        }
      }
    }
  }, [funnelAnswers]);

  useEffect(() => {
    if (
      status === "loading" ||
      !session ||
      isEqual(session.context.funnelData, funnelAnswers)
    )
      return;
    setFunnelAnswers(session.context.funnelData);
  }, [status, session]);

  return { funnelAnswers, setFunnelAnswers };
}
