import { jsxs as V, jsx as i } from "react/jsx-runtime";
import * as s from "react";
import { c as h, u as j } from "../../index-BlaUKar8.js";
import { u as C } from "../../index-CCavb-1K.js";
import { c as P } from "../../index-CVQolMUX.js";
import { P as y } from "../../index-u2zzFwMH.js";
import { c as w, R as z, I as T } from "../../index-DVK5FFd-.js";
import { u as B } from "../../index-eNC7-srW.js";
import { u as U } from "../../index-BVAIRv_c.js";
import { u as H } from "../../index-BZPx6jYI.js";
import { P as $ } from "../../index-BlE6Vgog.js";
import { cn as I } from "../../utils/cn.js";
import { focusRing as W } from "../../utils/focus-ring.js";
var g = "Radio", [X, G] = P(g), [Y, J] = X(g), N = s.forwardRef(
  (o, a) => {
    const {
      __scopeRadio: e,
      name: c,
      checked: r = !1,
      required: t,
      disabled: d,
      value: p = "on",
      onCheck: u,
      ...m
    } = o, [v, l] = s.useState(null), f = C(a, (b) => l(b)), n = s.useRef(!1), R = v ? !!v.closest("form") : !0;
    return /* @__PURE__ */ V(Y, { scope: e, checked: r, disabled: d, children: [
      /* @__PURE__ */ i(
        y.button,
        {
          type: "button",
          role: "radio",
          "aria-checked": r,
          "data-state": _(r),
          "data-disabled": d ? "" : void 0,
          disabled: d,
          value: p,
          ...m,
          ref: f,
          onClick: h(o.onClick, (b) => {
            r || u == null || u(), R && (n.current = b.isPropagationStopped(), n.current || b.stopPropagation());
          })
        }
      ),
      R && /* @__PURE__ */ i(
        Q,
        {
          control: v,
          bubbles: !n.current,
          name: c,
          value: p,
          checked: r,
          required: t,
          disabled: d,
          style: { transform: "translateX(-100%)" }
        }
      )
    ] });
  }
);
N.displayName = g;
var E = "RadioIndicator", S = s.forwardRef(
  (o, a) => {
    const { __scopeRadio: e, forceMount: c, ...r } = o, t = J(E, e);
    return /* @__PURE__ */ i($, { present: c || t.checked, children: /* @__PURE__ */ i(
      y.span,
      {
        "data-state": _(t.checked),
        "data-disabled": t.disabled ? "" : void 0,
        ...r,
        ref: a
      }
    ) });
  }
);
S.displayName = E;
var Q = (o) => {
  const { control: a, checked: e, bubbles: c = !0, ...r } = o, t = s.useRef(null), d = H(e), p = U(a);
  return s.useEffect(() => {
    const u = t.current, m = window.HTMLInputElement.prototype, l = Object.getOwnPropertyDescriptor(m, "checked").set;
    if (d !== e && l) {
      const f = new Event("click", { bubbles: c });
      l.call(u, e), u.dispatchEvent(f);
    }
  }, [d, e, c]), /* @__PURE__ */ i(
    "input",
    {
      type: "radio",
      "aria-hidden": !0,
      defaultChecked: e,
      ...r,
      tabIndex: -1,
      ref: t,
      style: {
        ...o.style,
        ...p,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0,
        margin: 0
      }
    }
  );
};
function _(o) {
  return o ? "checked" : "unchecked";
}
var Z = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"], k = "RadioGroup", [ee, ye] = P(k, [
  w,
  G
]), A = w(), x = G(), [oe, re] = ee(k), D = s.forwardRef(
  (o, a) => {
    const {
      __scopeRadioGroup: e,
      name: c,
      defaultValue: r,
      value: t,
      required: d = !1,
      disabled: p = !1,
      orientation: u,
      dir: m,
      loop: v = !0,
      onValueChange: l,
      ...f
    } = o, n = A(e), R = B(m), [b, K] = j({
      prop: t,
      defaultProp: r,
      onChange: l
    });
    return /* @__PURE__ */ i(
      oe,
      {
        scope: e,
        name: c,
        required: d,
        disabled: p,
        value: b,
        onValueChange: K,
        children: /* @__PURE__ */ i(
          z,
          {
            asChild: !0,
            ...n,
            orientation: u,
            dir: R,
            loop: v,
            children: /* @__PURE__ */ i(
              y.div,
              {
                role: "radiogroup",
                "aria-required": d,
                "aria-orientation": u,
                "data-disabled": p ? "" : void 0,
                dir: R,
                ...f,
                ref: a
              }
            )
          }
        )
      }
    );
  }
);
D.displayName = k;
var M = "RadioGroupItem", O = s.forwardRef(
  (o, a) => {
    const { __scopeRadioGroup: e, disabled: c, ...r } = o, t = re(M, e), d = t.disabled || c, p = A(e), u = x(e), m = s.useRef(null), v = C(a, m), l = t.value === r.value, f = s.useRef(!1);
    return s.useEffect(() => {
      const n = (b) => {
        Z.includes(b.key) && (f.current = !0);
      }, R = () => f.current = !1;
      return document.addEventListener("keydown", n), document.addEventListener("keyup", R), () => {
        document.removeEventListener("keydown", n), document.removeEventListener("keyup", R);
      };
    }, []), /* @__PURE__ */ i(
      T,
      {
        asChild: !0,
        ...p,
        focusable: !d,
        active: l,
        children: /* @__PURE__ */ i(
          N,
          {
            disabled: d,
            required: t.required,
            checked: l,
            ...u,
            ...r,
            name: t.name,
            ref: v,
            onCheck: () => t.onValueChange(r.value),
            onKeyDown: h((n) => {
              n.key === "Enter" && n.preventDefault();
            }),
            onFocus: h(r.onFocus, () => {
              var n;
              f.current && ((n = m.current) == null || n.click());
            })
          }
        )
      }
    );
  }
);
O.displayName = M;
var te = "RadioGroupIndicator", F = s.forwardRef(
  (o, a) => {
    const { __scopeRadioGroup: e, ...c } = o, r = x(e);
    return /* @__PURE__ */ i(S, { ...r, ...c, ref: a });
  }
);
F.displayName = te;
var q = D, L = O, ae = F;
const ie = s.forwardRef(({ className: o, ...a }, e) => /* @__PURE__ */ i(
  q,
  {
    className: I("grid gap-sp-3", o),
    ...a,
    ref: e
  }
));
ie.displayName = q.displayName;
const ne = s.forwardRef(({ className: o, ...a }, e) => /* @__PURE__ */ i(
  L,
  {
    ref: e,
    className: I(
      W,
      "peer aspect-square size-s-2 rounded-full border border-action-stroke-outline-default text-action-secondary-3 hover:bg-action-bg-outline-hover disabled:pointer-events-none disabled:border-action-stroke-outline-disabled disabled:bg-action-bg-primary-disabled data-[state=checked]:border-action-bg-primary-default data-[state=checked]:bg-action-bg-primary-default data-[state=checked]:hover:bg-action-bg-primary-hover data-[state=checked]:disabled:border-action-bg-primary-disabled data-[state=checked]:disabled:bg-action-bg-primary-disabled",
      o
    ),
    ...a,
    children: /* @__PURE__ */ i(ae, { className: "group flex items-center justify-center", children: /* @__PURE__ */ i("div", { className: "size-s-1 rounded-full bg-action-content-primary-default group-data-[disabled]:bg-action-content-primary-disabled" }) })
  }
));
ne.displayName = L.displayName;
export {
  ie as RadioGroup,
  ne as RadioGroupItem
};
