import { jsx as g, Fragment as Ye, jsxs as le } from "react/jsx-runtime";
import * as c from "react";
import { useState as nn, forwardRef as re } from "react";
import * as dt from "react-dom";
import rn from "react-dom";
import { c as A, u as Ze } from "../../index-BlaUKar8.js";
import { c as on, u as an } from "../../index-eNC7-srW.js";
import { u as B, S as cn } from "../../index-CCavb-1K.js";
import { P as M, d as sn } from "../../index-u2zzFwMH.js";
import { u as pe, a as z } from "../../index-CVQolMUX.js";
import { u as ln, c as ft, A as un, C as dn, a as fn, V as pn, b as vn } from "../../index-D8wlo3hw.js";
import { u as Xe } from "../../index-BosAEyXu.js";
import { u as hn } from "../../index-BZPx6jYI.js";
import { cn as te } from "../../utils/cn.js";
function Qe(e, [n, t]) {
  return Math.min(t, Math.max(n, e));
}
function mn(e, n = []) {
  let t = [];
  function r(i, s) {
    const o = c.createContext(s), u = t.length;
    t = [...t, s];
    const d = (m) => {
      var y;
      const { scope: S, children: w, ...b } = m, l = ((y = S == null ? void 0 : S[e]) == null ? void 0 : y[u]) || o, f = c.useMemo(() => b, Object.values(b));
      return /* @__PURE__ */ g(l.Provider, { value: f, children: w });
    };
    d.displayName = i + "Provider";
    function p(m, S) {
      var l;
      const w = ((l = S == null ? void 0 : S[e]) == null ? void 0 : l[u]) || o, b = c.useContext(w);
      if (b) return b;
      if (s !== void 0) return s;
      throw new Error(`\`${m}\` must be used within \`${i}\``);
    }
    return [d, p];
  }
  const a = () => {
    const i = t.map((s) => c.createContext(s));
    return function(o) {
      const u = (o == null ? void 0 : o[e]) || i;
      return c.useMemo(
        () => ({ [`__scope${e}`]: { ...o, [e]: u } }),
        [o, u]
      );
    };
  };
  return a.scopeName = e, [r, gn(a, ...n)];
}
function gn(...e) {
  const n = e[0];
  if (e.length === 1) return n;
  const t = () => {
    const r = e.map((a) => ({
      useScope: a(),
      scopeName: a.scopeName
    }));
    return function(i) {
      const s = r.reduce((o, { useScope: u, scopeName: d }) => {
        const m = u(i)[`__scope${d}`];
        return { ...o, ...m };
      }, {});
      return c.useMemo(() => ({ [`__scope${n.scopeName}`]: s }), [s]);
    };
  };
  return t.scopeName = n.scopeName, t;
}
var Sn = "DismissableLayer", Ve = "dismissableLayer.update", yn = "dismissableLayer.pointerDownOutside", wn = "dismissableLayer.focusOutside", Je, pt = c.createContext({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), vt = c.forwardRef(
  (e, n) => {
    const {
      disableOutsidePointerEvents: t = !1,
      onEscapeKeyDown: r,
      onPointerDownOutside: a,
      onFocusOutside: i,
      onInteractOutside: s,
      onDismiss: o,
      ...u
    } = e, d = c.useContext(pt), [p, m] = c.useState(null), S = (p == null ? void 0 : p.ownerDocument) ?? (globalThis == null ? void 0 : globalThis.document), [, w] = c.useState({}), b = B(n, (C) => m(C)), l = Array.from(d.layers), [f] = [...d.layersWithOutsidePointerEventsDisabled].slice(-1), y = l.indexOf(f), v = p ? l.indexOf(p) : -1, h = d.layersWithOutsidePointerEventsDisabled.size > 0, x = v >= y, P = Cn((C) => {
      const O = C.target, L = [...d.branches].some((F) => F.contains(O));
      !x || L || (a == null || a(C), s == null || s(C), C.defaultPrevented || o == null || o());
    }, S), _ = xn((C) => {
      const O = C.target;
      [...d.branches].some((F) => F.contains(O)) || (i == null || i(C), s == null || s(C), C.defaultPrevented || o == null || o());
    }, S);
    return ln((C) => {
      v === d.layers.size - 1 && (r == null || r(C), !C.defaultPrevented && o && (C.preventDefault(), o()));
    }, S), c.useEffect(() => {
      if (p)
        return t && (d.layersWithOutsidePointerEventsDisabled.size === 0 && (Je = S.body.style.pointerEvents, S.body.style.pointerEvents = "none"), d.layersWithOutsidePointerEventsDisabled.add(p)), d.layers.add(p), et(), () => {
          t && d.layersWithOutsidePointerEventsDisabled.size === 1 && (S.body.style.pointerEvents = Je);
        };
    }, [p, S, t, d]), c.useEffect(() => () => {
      p && (d.layers.delete(p), d.layersWithOutsidePointerEventsDisabled.delete(p), et());
    }, [p, d]), c.useEffect(() => {
      const C = () => w({});
      return document.addEventListener(Ve, C), () => document.removeEventListener(Ve, C);
    }, []), /* @__PURE__ */ g(
      M.div,
      {
        ...u,
        ref: b,
        style: {
          pointerEvents: h ? x ? "auto" : "none" : void 0,
          ...e.style
        },
        onFocusCapture: A(e.onFocusCapture, _.onFocusCapture),
        onBlurCapture: A(e.onBlurCapture, _.onBlurCapture),
        onPointerDownCapture: A(
          e.onPointerDownCapture,
          P.onPointerDownCapture
        )
      }
    );
  }
);
vt.displayName = Sn;
var bn = "DismissableLayerBranch", En = c.forwardRef((e, n) => {
  const t = c.useContext(pt), r = c.useRef(null), a = B(n, r);
  return c.useEffect(() => {
    const i = r.current;
    if (i)
      return t.branches.add(i), () => {
        t.branches.delete(i);
      };
  }, [t.branches]), /* @__PURE__ */ g(M.div, { ...e, ref: a });
});
En.displayName = bn;
function Cn(e, n = globalThis == null ? void 0 : globalThis.document) {
  const t = pe(e), r = c.useRef(!1), a = c.useRef(() => {
  });
  return c.useEffect(() => {
    const i = (o) => {
      if (o.target && !r.current) {
        let u = function() {
          ht(
            yn,
            t,
            d,
            { discrete: !0 }
          );
        };
        const d = { originalEvent: o };
        o.pointerType === "touch" ? (n.removeEventListener("click", a.current), a.current = u, n.addEventListener("click", a.current, { once: !0 })) : u();
      } else
        n.removeEventListener("click", a.current);
      r.current = !1;
    }, s = window.setTimeout(() => {
      n.addEventListener("pointerdown", i);
    }, 0);
    return () => {
      window.clearTimeout(s), n.removeEventListener("pointerdown", i), n.removeEventListener("click", a.current);
    };
  }, [n, t]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => r.current = !0
  };
}
function xn(e, n = globalThis == null ? void 0 : globalThis.document) {
  const t = pe(e), r = c.useRef(!1);
  return c.useEffect(() => {
    const a = (i) => {
      i.target && !r.current && ht(wn, t, { originalEvent: i }, {
        discrete: !1
      });
    };
    return n.addEventListener("focusin", a), () => n.removeEventListener("focusin", a);
  }, [n, t]), {
    onFocusCapture: () => r.current = !0,
    onBlurCapture: () => r.current = !1
  };
}
function et() {
  const e = new CustomEvent(Ve);
  document.dispatchEvent(e);
}
function ht(e, n, t, { discrete: r }) {
  const a = t.originalEvent.target, i = new CustomEvent(e, { bubbles: !1, cancelable: !0, detail: t });
  n && a.addEventListener(e, n, { once: !0 }), r ? sn(a, i) : a.dispatchEvent(i);
}
var Le = 0;
function Pn() {
  c.useEffect(() => {
    const e = document.querySelectorAll("[data-radix-focus-guard]");
    return document.body.insertAdjacentElement("afterbegin", e[0] ?? tt()), document.body.insertAdjacentElement("beforeend", e[1] ?? tt()), Le++, () => {
      Le === 1 && document.querySelectorAll("[data-radix-focus-guard]").forEach((n) => n.remove()), Le--;
    };
  }, []);
}
function tt() {
  const e = document.createElement("span");
  return e.setAttribute("data-radix-focus-guard", ""), e.tabIndex = 0, e.style.outline = "none", e.style.opacity = "0", e.style.position = "fixed", e.style.pointerEvents = "none", e;
}
var ke = "focusScope.autoFocusOnMount", De = "focusScope.autoFocusOnUnmount", nt = { bubbles: !1, cancelable: !0 }, Tn = "FocusScope", mt = c.forwardRef((e, n) => {
  const {
    loop: t = !1,
    trapped: r = !1,
    onMountAutoFocus: a,
    onUnmountAutoFocus: i,
    ...s
  } = e, [o, u] = c.useState(null), d = pe(a), p = pe(i), m = c.useRef(null), S = B(n, (l) => u(l)), w = c.useRef({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  c.useEffect(() => {
    if (r) {
      let l = function(h) {
        if (w.paused || !o) return;
        const x = h.target;
        o.contains(x) ? m.current = x : q(m.current, { select: !0 });
      }, f = function(h) {
        if (w.paused || !o) return;
        const x = h.relatedTarget;
        x !== null && (o.contains(x) || q(m.current, { select: !0 }));
      }, y = function(h) {
        if (document.activeElement === document.body)
          for (const P of h)
            P.removedNodes.length > 0 && q(o);
      };
      document.addEventListener("focusin", l), document.addEventListener("focusout", f);
      const v = new MutationObserver(y);
      return o && v.observe(o, { childList: !0, subtree: !0 }), () => {
        document.removeEventListener("focusin", l), document.removeEventListener("focusout", f), v.disconnect();
      };
    }
  }, [r, o, w.paused]), c.useEffect(() => {
    if (o) {
      ot.add(w);
      const l = document.activeElement;
      if (!o.contains(l)) {
        const y = new CustomEvent(ke, nt);
        o.addEventListener(ke, d), o.dispatchEvent(y), y.defaultPrevented || (In(Mn(gt(o)), { select: !0 }), document.activeElement === l && q(o));
      }
      return () => {
        o.removeEventListener(ke, d), setTimeout(() => {
          const y = new CustomEvent(De, nt);
          o.addEventListener(De, p), o.dispatchEvent(y), y.defaultPrevented || q(l ?? document.body, { select: !0 }), o.removeEventListener(De, p), ot.remove(w);
        }, 0);
      };
    }
  }, [o, d, p, w]);
  const b = c.useCallback(
    (l) => {
      if (!t && !r || w.paused) return;
      const f = l.key === "Tab" && !l.altKey && !l.ctrlKey && !l.metaKey, y = document.activeElement;
      if (f && y) {
        const v = l.currentTarget, [h, x] = Rn(v);
        h && x ? !l.shiftKey && y === x ? (l.preventDefault(), t && q(h, { select: !0 })) : l.shiftKey && y === h && (l.preventDefault(), t && q(x, { select: !0 })) : y === v && l.preventDefault();
      }
    },
    [t, r, w.paused]
  );
  return /* @__PURE__ */ g(M.div, { tabIndex: -1, ...s, ref: S, onKeyDown: b });
});
mt.displayName = Tn;
function In(e, { select: n = !1 } = {}) {
  const t = document.activeElement;
  for (const r of e)
    if (q(r, { select: n }), document.activeElement !== t) return;
}
function Rn(e) {
  const n = gt(e), t = rt(n, e), r = rt(n.reverse(), e);
  return [t, r];
}
function gt(e) {
  const n = [], t = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (r) => {
      const a = r.tagName === "INPUT" && r.type === "hidden";
      return r.disabled || r.hidden || a ? NodeFilter.FILTER_SKIP : r.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; t.nextNode(); ) n.push(t.currentNode);
  return n;
}
function rt(e, n) {
  for (const t of e)
    if (!Nn(t, { upTo: n })) return t;
}
function Nn(e, { upTo: n }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (n !== void 0 && e === n) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function On(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function q(e, { select: n = !1 } = {}) {
  if (e && e.focus) {
    const t = document.activeElement;
    e.focus({ preventScroll: !0 }), e !== t && On(e) && n && e.select();
  }
}
var ot = An();
function An() {
  let e = [];
  return {
    add(n) {
      const t = e[0];
      n !== t && (t == null || t.pause()), e = at(e, n), e.unshift(n);
    },
    remove(n) {
      var t;
      e = at(e, n), (t = e[0]) == null || t.resume();
    }
  };
}
function at(e, n) {
  const t = [...e], r = t.indexOf(n);
  return r !== -1 && t.splice(r, 1), t;
}
function Mn(e) {
  return e.filter((n) => n.tagName !== "A");
}
var _n = "Portal", St = c.forwardRef((e, n) => {
  var o;
  const { container: t, ...r } = e, [a, i] = c.useState(!1);
  z(() => i(!0), []);
  const s = t || a && ((o = globalThis == null ? void 0 : globalThis.document) == null ? void 0 : o.body);
  return s ? rn.createPortal(/* @__PURE__ */ g(M.div, { ...r, ref: n }), s) : null;
});
St.displayName = _n;
var Ln = function(e) {
  if (typeof document > "u")
    return null;
  var n = Array.isArray(e) ? e[0] : e;
  return n.ownerDocument.body;
}, ae = /* @__PURE__ */ new WeakMap(), ge = /* @__PURE__ */ new WeakMap(), Se = {}, Be = 0, yt = function(e) {
  return e && (e.host || yt(e.parentNode));
}, kn = function(e, n) {
  return n.map(function(t) {
    if (e.contains(t))
      return t;
    var r = yt(t);
    return r && e.contains(r) ? r : (console.error("aria-hidden", t, "in not contained inside", e, ". Doing nothing"), null);
  }).filter(function(t) {
    return !!t;
  });
}, Dn = function(e, n, t, r) {
  var a = kn(n, Array.isArray(e) ? e : [e]);
  Se[t] || (Se[t] = /* @__PURE__ */ new WeakMap());
  var i = Se[t], s = [], o = /* @__PURE__ */ new Set(), u = new Set(a), d = function(m) {
    !m || o.has(m) || (o.add(m), d(m.parentNode));
  };
  a.forEach(d);
  var p = function(m) {
    !m || u.has(m) || Array.prototype.forEach.call(m.children, function(S) {
      if (o.has(S))
        p(S);
      else
        try {
          var w = S.getAttribute(r), b = w !== null && w !== "false", l = (ae.get(S) || 0) + 1, f = (i.get(S) || 0) + 1;
          ae.set(S, l), i.set(S, f), s.push(S), l === 1 && b && ge.set(S, !0), f === 1 && S.setAttribute(t, "true"), b || S.setAttribute(r, "true");
        } catch (y) {
          console.error("aria-hidden: cannot operate on ", S, y);
        }
    });
  };
  return p(n), o.clear(), Be++, function() {
    s.forEach(function(m) {
      var S = ae.get(m) - 1, w = i.get(m) - 1;
      ae.set(m, S), i.set(m, w), S || (ge.has(m) || m.removeAttribute(r), ge.delete(m)), w || m.removeAttribute(t);
    }), Be--, Be || (ae = /* @__PURE__ */ new WeakMap(), ae = /* @__PURE__ */ new WeakMap(), ge = /* @__PURE__ */ new WeakMap(), Se = {});
  };
}, Bn = function(e, n, t) {
  t === void 0 && (t = "data-aria-hidden");
  var r = Array.from(Array.isArray(e) ? e : [e]), a = Ln(e);
  return a ? (r.push.apply(r, Array.from(a.querySelectorAll("[aria-live]"))), Dn(r, a, t, "aria-hidden")) : function() {
    return null;
  };
}, V = function() {
  return V = Object.assign || function(n) {
    for (var t, r = 1, a = arguments.length; r < a; r++) {
      t = arguments[r];
      for (var i in t) Object.prototype.hasOwnProperty.call(t, i) && (n[i] = t[i]);
    }
    return n;
  }, V.apply(this, arguments);
};
function wt(e, n) {
  var t = {};
  for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && n.indexOf(r) < 0 && (t[r] = e[r]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var a = 0, r = Object.getOwnPropertySymbols(e); a < r.length; a++)
      n.indexOf(r[a]) < 0 && Object.prototype.propertyIsEnumerable.call(e, r[a]) && (t[r[a]] = e[r[a]]);
  return t;
}
function Fn(e, n, t) {
  if (t || arguments.length === 2) for (var r = 0, a = n.length, i; r < a; r++)
    (i || !(r in n)) && (i || (i = Array.prototype.slice.call(n, 0, r)), i[r] = n[r]);
  return e.concat(i || Array.prototype.slice.call(n));
}
var be = "right-scroll-bar-position", Ee = "width-before-scroll-bar", Wn = "with-scroll-bars-hidden", Hn = "--removed-body-scroll-bar-size";
function Fe(e, n) {
  return typeof e == "function" ? e(n) : e && (e.current = n), e;
}
function Vn(e, n) {
  var t = nn(function() {
    return {
      // value
      value: e,
      // last callback
      callback: n,
      // "memoized" public interface
      facade: {
        get current() {
          return t.value;
        },
        set current(r) {
          var a = t.value;
          a !== r && (t.value = r, t.callback(r, a));
        }
      }
    };
  })[0];
  return t.callback = n, t.facade;
}
var Un = typeof window < "u" ? c.useLayoutEffect : c.useEffect, ct = /* @__PURE__ */ new WeakMap();
function Kn(e, n) {
  var t = Vn(null, function(r) {
    return e.forEach(function(a) {
      return Fe(a, r);
    });
  });
  return Un(function() {
    var r = ct.get(t);
    if (r) {
      var a = new Set(r), i = new Set(e), s = t.current;
      a.forEach(function(o) {
        i.has(o) || Fe(o, null);
      }), i.forEach(function(o) {
        a.has(o) || Fe(o, s);
      });
    }
    ct.set(t, e);
  }, [e]), t;
}
function Gn(e) {
  return e;
}
function jn(e, n) {
  n === void 0 && (n = Gn);
  var t = [], r = !1, a = {
    read: function() {
      if (r)
        throw new Error("Sidecar: could not `read` from an `assigned` medium. `read` could be used only with `useMedium`.");
      return t.length ? t[t.length - 1] : e;
    },
    useMedium: function(i) {
      var s = n(i, r);
      return t.push(s), function() {
        t = t.filter(function(o) {
          return o !== s;
        });
      };
    },
    assignSyncMedium: function(i) {
      for (r = !0; t.length; ) {
        var s = t;
        t = [], s.forEach(i);
      }
      t = {
        push: function(o) {
          return i(o);
        },
        filter: function() {
          return t;
        }
      };
    },
    assignMedium: function(i) {
      r = !0;
      var s = [];
      if (t.length) {
        var o = t;
        t = [], o.forEach(i), s = t;
      }
      var u = function() {
        var p = s;
        s = [], p.forEach(i);
      }, d = function() {
        return Promise.resolve().then(u);
      };
      d(), t = {
        push: function(p) {
          s.push(p), d();
        },
        filter: function(p) {
          return s = s.filter(p), t;
        }
      };
    }
  };
  return a;
}
function $n(e) {
  e === void 0 && (e = {});
  var n = jn(null);
  return n.options = V({ async: !0, ssr: !1 }, e), n;
}
var bt = function(e) {
  var n = e.sideCar, t = wt(e, ["sideCar"]);
  if (!n)
    throw new Error("Sidecar: please provide `sideCar` property to import the right car");
  var r = n.read();
  if (!r)
    throw new Error("Sidecar medium not found");
  return c.createElement(r, V({}, t));
};
bt.isSideCarExport = !0;
function zn(e, n) {
  return e.useMedium(n), bt;
}
var Et = $n(), We = function() {
}, xe = c.forwardRef(function(e, n) {
  var t = c.useRef(null), r = c.useState({
    onScrollCapture: We,
    onWheelCapture: We,
    onTouchMoveCapture: We
  }), a = r[0], i = r[1], s = e.forwardProps, o = e.children, u = e.className, d = e.removeScrollBar, p = e.enabled, m = e.shards, S = e.sideCar, w = e.noIsolation, b = e.inert, l = e.allowPinchZoom, f = e.as, y = f === void 0 ? "div" : f, v = e.gapMode, h = wt(e, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as", "gapMode"]), x = S, P = Kn([t, n]), _ = V(V({}, h), a);
  return c.createElement(
    c.Fragment,
    null,
    p && c.createElement(x, { sideCar: Et, removeScrollBar: d, shards: m, noIsolation: w, inert: b, setCallbacks: i, allowPinchZoom: !!l, lockRef: t, gapMode: v }),
    s ? c.cloneElement(c.Children.only(o), V(V({}, _), { ref: P })) : c.createElement(y, V({}, _, { className: u, ref: P }), o)
  );
});
xe.defaultProps = {
  enabled: !0,
  removeScrollBar: !0,
  inert: !1
};
xe.classNames = {
  fullWidth: Ee,
  zeroRight: be
};
var Yn = function() {
  if (typeof __webpack_nonce__ < "u")
    return __webpack_nonce__;
};
function Xn() {
  if (!document)
    return null;
  var e = document.createElement("style");
  e.type = "text/css";
  var n = Yn();
  return n && e.setAttribute("nonce", n), e;
}
function qn(e, n) {
  e.styleSheet ? e.styleSheet.cssText = n : e.appendChild(document.createTextNode(n));
}
function Zn(e) {
  var n = document.head || document.getElementsByTagName("head")[0];
  n.appendChild(e);
}
var Qn = function() {
  var e = 0, n = null;
  return {
    add: function(t) {
      e == 0 && (n = Xn()) && (qn(n, t), Zn(n)), e++;
    },
    remove: function() {
      e--, !e && n && (n.parentNode && n.parentNode.removeChild(n), n = null);
    }
  };
}, Jn = function() {
  var e = Qn();
  return function(n, t) {
    c.useEffect(function() {
      return e.add(n), function() {
        e.remove();
      };
    }, [n && t]);
  };
}, Ct = function() {
  var e = Jn(), n = function(t) {
    var r = t.styles, a = t.dynamic;
    return e(r, a), null;
  };
  return n;
}, er = {
  left: 0,
  top: 0,
  right: 0,
  gap: 0
}, He = function(e) {
  return parseInt(e || "", 10) || 0;
}, tr = function(e) {
  var n = window.getComputedStyle(document.body), t = n[e === "padding" ? "paddingLeft" : "marginLeft"], r = n[e === "padding" ? "paddingTop" : "marginTop"], a = n[e === "padding" ? "paddingRight" : "marginRight"];
  return [He(t), He(r), He(a)];
}, nr = function(e) {
  if (e === void 0 && (e = "margin"), typeof window > "u")
    return er;
  var n = tr(e), t = document.documentElement.clientWidth, r = window.innerWidth;
  return {
    left: n[0],
    top: n[1],
    right: n[2],
    gap: Math.max(0, r - t + n[2] - n[0])
  };
}, rr = Ct(), se = "data-scroll-locked", or = function(e, n, t, r) {
  var a = e.left, i = e.top, s = e.right, o = e.gap;
  return t === void 0 && (t = "margin"), `
  .`.concat(Wn, ` {
   overflow: hidden `).concat(r, `;
   padding-right: `).concat(o, "px ").concat(r, `;
  }
  body[`).concat(se, `] {
    overflow: hidden `).concat(r, `;
    overscroll-behavior: contain;
    `).concat([
    n && "position: relative ".concat(r, ";"),
    t === "margin" && `
    padding-left: `.concat(a, `px;
    padding-top: `).concat(i, `px;
    padding-right: `).concat(s, `px;
    margin-left:0;
    margin-top:0;
    margin-right: `).concat(o, "px ").concat(r, `;
    `),
    t === "padding" && "padding-right: ".concat(o, "px ").concat(r, ";")
  ].filter(Boolean).join(""), `
  }
  
  .`).concat(be, ` {
    right: `).concat(o, "px ").concat(r, `;
  }
  
  .`).concat(Ee, ` {
    margin-right: `).concat(o, "px ").concat(r, `;
  }
  
  .`).concat(be, " .").concat(be, ` {
    right: 0 `).concat(r, `;
  }
  
  .`).concat(Ee, " .").concat(Ee, ` {
    margin-right: 0 `).concat(r, `;
  }
  
  body[`).concat(se, `] {
    `).concat(Hn, ": ").concat(o, `px;
  }
`);
}, it = function() {
  var e = parseInt(document.body.getAttribute(se) || "0", 10);
  return isFinite(e) ? e : 0;
}, ar = function() {
  c.useEffect(function() {
    return document.body.setAttribute(se, (it() + 1).toString()), function() {
      var e = it() - 1;
      e <= 0 ? document.body.removeAttribute(se) : document.body.setAttribute(se, e.toString());
    };
  }, []);
}, cr = function(e) {
  var n = e.noRelative, t = e.noImportant, r = e.gapMode, a = r === void 0 ? "margin" : r;
  ar();
  var i = c.useMemo(function() {
    return nr(a);
  }, [a]);
  return c.createElement(rr, { styles: or(i, !n, a, t ? "" : "!important") });
}, Ue = !1;
if (typeof window < "u")
  try {
    var ye = Object.defineProperty({}, "passive", {
      get: function() {
        return Ue = !0, !0;
      }
    });
    window.addEventListener("test", ye, ye), window.removeEventListener("test", ye, ye);
  } catch {
    Ue = !1;
  }
var ce = Ue ? { passive: !1 } : !1, ir = function(e) {
  return e.tagName === "TEXTAREA";
}, xt = function(e, n) {
  if (!(e instanceof Element))
    return !1;
  var t = window.getComputedStyle(e);
  return (
    // not-not-scrollable
    t[n] !== "hidden" && // contains scroll inside self
    !(t.overflowY === t.overflowX && !ir(e) && t[n] === "visible")
  );
}, sr = function(e) {
  return xt(e, "overflowY");
}, lr = function(e) {
  return xt(e, "overflowX");
}, st = function(e, n) {
  var t = n.ownerDocument, r = n;
  do {
    typeof ShadowRoot < "u" && r instanceof ShadowRoot && (r = r.host);
    var a = Pt(e, r);
    if (a) {
      var i = Tt(e, r), s = i[1], o = i[2];
      if (s > o)
        return !0;
    }
    r = r.parentNode;
  } while (r && r !== t.body);
  return !1;
}, ur = function(e) {
  var n = e.scrollTop, t = e.scrollHeight, r = e.clientHeight;
  return [
    n,
    t,
    r
  ];
}, dr = function(e) {
  var n = e.scrollLeft, t = e.scrollWidth, r = e.clientWidth;
  return [
    n,
    t,
    r
  ];
}, Pt = function(e, n) {
  return e === "v" ? sr(n) : lr(n);
}, Tt = function(e, n) {
  return e === "v" ? ur(n) : dr(n);
}, fr = function(e, n) {
  return e === "h" && n === "rtl" ? -1 : 1;
}, pr = function(e, n, t, r, a) {
  var i = fr(e, window.getComputedStyle(n).direction), s = i * r, o = t.target, u = n.contains(o), d = !1, p = s > 0, m = 0, S = 0;
  do {
    var w = Tt(e, o), b = w[0], l = w[1], f = w[2], y = l - f - i * b;
    (b || y) && Pt(e, o) && (m += y, S += b), o instanceof ShadowRoot ? o = o.host : o = o.parentNode;
  } while (
    // portaled content
    !u && o !== document.body || // self content
    u && (n.contains(o) || n === o)
  );
  return (p && (Math.abs(m) < 1 || !a) || !p && (Math.abs(S) < 1 || !a)) && (d = !0), d;
}, we = function(e) {
  return "changedTouches" in e ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [0, 0];
}, lt = function(e) {
  return [e.deltaX, e.deltaY];
}, ut = function(e) {
  return e && "current" in e ? e.current : e;
}, vr = function(e, n) {
  return e[0] === n[0] && e[1] === n[1];
}, hr = function(e) {
  return `
  .block-interactivity-`.concat(e, ` {pointer-events: none;}
  .allow-interactivity-`).concat(e, ` {pointer-events: all;}
`);
}, mr = 0, ie = [];
function gr(e) {
  var n = c.useRef([]), t = c.useRef([0, 0]), r = c.useRef(), a = c.useState(mr++)[0], i = c.useState(Ct)[0], s = c.useRef(e);
  c.useEffect(function() {
    s.current = e;
  }, [e]), c.useEffect(function() {
    if (e.inert) {
      document.body.classList.add("block-interactivity-".concat(a));
      var l = Fn([e.lockRef.current], (e.shards || []).map(ut), !0).filter(Boolean);
      return l.forEach(function(f) {
        return f.classList.add("allow-interactivity-".concat(a));
      }), function() {
        document.body.classList.remove("block-interactivity-".concat(a)), l.forEach(function(f) {
          return f.classList.remove("allow-interactivity-".concat(a));
        });
      };
    }
  }, [e.inert, e.lockRef.current, e.shards]);
  var o = c.useCallback(function(l, f) {
    if ("touches" in l && l.touches.length === 2 || l.type === "wheel" && l.ctrlKey)
      return !s.current.allowPinchZoom;
    var y = we(l), v = t.current, h = "deltaX" in l ? l.deltaX : v[0] - y[0], x = "deltaY" in l ? l.deltaY : v[1] - y[1], P, _ = l.target, C = Math.abs(h) > Math.abs(x) ? "h" : "v";
    if ("touches" in l && C === "h" && _.type === "range")
      return !1;
    var O = st(C, _);
    if (!O)
      return !0;
    if (O ? P = C : (P = C === "v" ? "h" : "v", O = st(C, _)), !O)
      return !1;
    if (!r.current && "changedTouches" in l && (h || x) && (r.current = P), !P)
      return !0;
    var L = r.current || P;
    return pr(L, f, l, L === "h" ? h : x, !0);
  }, []), u = c.useCallback(function(l) {
    var f = l;
    if (!(!ie.length || ie[ie.length - 1] !== i)) {
      var y = "deltaY" in f ? lt(f) : we(f), v = n.current.filter(function(P) {
        return P.name === f.type && (P.target === f.target || f.target === P.shadowParent) && vr(P.delta, y);
      })[0];
      if (v && v.should) {
        f.cancelable && f.preventDefault();
        return;
      }
      if (!v) {
        var h = (s.current.shards || []).map(ut).filter(Boolean).filter(function(P) {
          return P.contains(f.target);
        }), x = h.length > 0 ? o(f, h[0]) : !s.current.noIsolation;
        x && f.cancelable && f.preventDefault();
      }
    }
  }, []), d = c.useCallback(function(l, f, y, v) {
    var h = { name: l, delta: f, target: y, should: v, shadowParent: Sr(y) };
    n.current.push(h), setTimeout(function() {
      n.current = n.current.filter(function(x) {
        return x !== h;
      });
    }, 1);
  }, []), p = c.useCallback(function(l) {
    t.current = we(l), r.current = void 0;
  }, []), m = c.useCallback(function(l) {
    d(l.type, lt(l), l.target, o(l, e.lockRef.current));
  }, []), S = c.useCallback(function(l) {
    d(l.type, we(l), l.target, o(l, e.lockRef.current));
  }, []);
  c.useEffect(function() {
    return ie.push(i), e.setCallbacks({
      onScrollCapture: m,
      onWheelCapture: m,
      onTouchMoveCapture: S
    }), document.addEventListener("wheel", u, ce), document.addEventListener("touchmove", u, ce), document.addEventListener("touchstart", p, ce), function() {
      ie = ie.filter(function(l) {
        return l !== i;
      }), document.removeEventListener("wheel", u, ce), document.removeEventListener("touchmove", u, ce), document.removeEventListener("touchstart", p, ce);
    };
  }, []);
  var w = e.removeScrollBar, b = e.inert;
  return c.createElement(
    c.Fragment,
    null,
    b ? c.createElement(i, { styles: hr(a) }) : null,
    w ? c.createElement(cr, { gapMode: e.gapMode }) : null
  );
}
function Sr(e) {
  for (var n = null; e !== null; )
    e instanceof ShadowRoot && (n = e.host, e = e.host), e = e.parentNode;
  return n;
}
const yr = zn(Et, gr);
var It = c.forwardRef(function(e, n) {
  return c.createElement(xe, V({}, e, { ref: n, sideCar: yr }));
});
It.classNames = xe.classNames;
var wr = [" ", "Enter", "ArrowUp", "ArrowDown"], br = [" ", "Enter"], ve = "Select", [Pe, Te, Er] = on(ve), [ue, yo] = mn(ve, [
  Er,
  ft
]), Ie = ft(), [Cr, Z] = ue(ve), [xr, Pr] = ue(ve), Rt = (e) => {
  const {
    __scopeSelect: n,
    children: t,
    open: r,
    defaultOpen: a,
    onOpenChange: i,
    value: s,
    defaultValue: o,
    onValueChange: u,
    dir: d,
    name: p,
    autoComplete: m,
    disabled: S,
    required: w,
    form: b
  } = e, l = Ie(n), [f, y] = c.useState(null), [v, h] = c.useState(null), [x, P] = c.useState(!1), _ = an(d), [C = !1, O] = Ze({
    prop: r,
    defaultProp: a,
    onChange: i
  }), [L, F] = Ze({
    prop: s,
    defaultProp: o,
    onChange: u
  }), U = c.useRef(null), K = f ? b || !!f.closest("form") : !0, [Y, G] = c.useState(/* @__PURE__ */ new Set()), j = Array.from(Y).map((k) => k.props.value).join(";");
  return /* @__PURE__ */ g(vn, { ...l, children: /* @__PURE__ */ le(
    Cr,
    {
      required: w,
      scope: n,
      trigger: f,
      onTriggerChange: y,
      valueNode: v,
      onValueNodeChange: h,
      valueNodeHasChildren: x,
      onValueNodeHasChildrenChange: P,
      contentId: Xe(),
      value: L,
      onValueChange: F,
      open: C,
      onOpenChange: O,
      dir: _,
      triggerPointerDownPosRef: U,
      disabled: S,
      children: [
        /* @__PURE__ */ g(Pe.Provider, { scope: n, children: /* @__PURE__ */ g(
          xr,
          {
            scope: e.__scopeSelect,
            onNativeOptionAdd: c.useCallback((k) => {
              G((H) => new Set(H).add(k));
            }, []),
            onNativeOptionRemove: c.useCallback((k) => {
              G((H) => {
                const $ = new Set(H);
                return $.delete(k), $;
              });
            }, []),
            children: t
          }
        ) }),
        K ? /* @__PURE__ */ le(
          Zt,
          {
            "aria-hidden": !0,
            required: w,
            tabIndex: -1,
            name: p,
            autoComplete: m,
            value: L,
            onChange: (k) => F(k.target.value),
            disabled: S,
            form: b,
            children: [
              L === void 0 ? /* @__PURE__ */ g("option", { value: "" }) : null,
              Array.from(Y)
            ]
          },
          j
        ) : null
      ]
    }
  ) });
};
Rt.displayName = ve;
var Nt = "SelectTrigger", Ot = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, disabled: r = !1, ...a } = e, i = Ie(t), s = Z(Nt, t), o = s.disabled || r, u = B(n, s.onTriggerChange), d = Te(t), p = c.useRef("touch"), [m, S, w] = Qt((l) => {
      const f = d().filter((h) => !h.disabled), y = f.find((h) => h.value === s.value), v = Jt(f, l, y);
      v !== void 0 && s.onValueChange(v.value);
    }), b = (l) => {
      o || (s.onOpenChange(!0), w()), l && (s.triggerPointerDownPosRef.current = {
        x: Math.round(l.pageX),
        y: Math.round(l.pageY)
      });
    };
    return /* @__PURE__ */ g(un, { asChild: !0, ...i, children: /* @__PURE__ */ g(
      M.button,
      {
        type: "button",
        role: "combobox",
        "aria-controls": s.contentId,
        "aria-expanded": s.open,
        "aria-required": s.required,
        "aria-autocomplete": "none",
        dir: s.dir,
        "data-state": s.open ? "open" : "closed",
        disabled: o,
        "data-disabled": o ? "" : void 0,
        "data-placeholder": qt(s.value) ? "" : void 0,
        ...a,
        ref: u,
        onClick: A(a.onClick, (l) => {
          l.currentTarget.focus(), p.current !== "mouse" && b(l);
        }),
        onPointerDown: A(a.onPointerDown, (l) => {
          p.current = l.pointerType;
          const f = l.target;
          f.hasPointerCapture(l.pointerId) && f.releasePointerCapture(l.pointerId), l.button === 0 && l.ctrlKey === !1 && l.pointerType === "mouse" && (b(l), l.preventDefault());
        }),
        onKeyDown: A(a.onKeyDown, (l) => {
          const f = m.current !== "";
          !(l.ctrlKey || l.altKey || l.metaKey) && l.key.length === 1 && S(l.key), !(f && l.key === " ") && wr.includes(l.key) && (b(), l.preventDefault());
        })
      }
    ) });
  }
);
Ot.displayName = Nt;
var At = "SelectValue", Mt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, className: r, style: a, children: i, placeholder: s = "", ...o } = e, u = Z(At, t), { onValueNodeHasChildrenChange: d } = u, p = i !== void 0, m = B(n, u.onValueNodeChange);
    return z(() => {
      d(p);
    }, [d, p]), /* @__PURE__ */ g(
      M.span,
      {
        ...o,
        ref: m,
        style: { pointerEvents: "none" },
        children: qt(u.value) ? /* @__PURE__ */ g(Ye, { children: s }) : i
      }
    );
  }
);
Mt.displayName = At;
var Tr = "SelectIcon", _t = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, children: r, ...a } = e;
    return /* @__PURE__ */ g(M.span, { "aria-hidden": !0, ...a, ref: n, children: r || "▼" });
  }
);
_t.displayName = Tr;
var Ir = "SelectPortal", Lt = (e) => /* @__PURE__ */ g(St, { asChild: !0, ...e });
Lt.displayName = Ir;
var ne = "SelectContent", kt = c.forwardRef(
  (e, n) => {
    const t = Z(ne, e.__scopeSelect), [r, a] = c.useState();
    if (z(() => {
      a(new DocumentFragment());
    }, []), !t.open) {
      const i = r;
      return i ? dt.createPortal(
        /* @__PURE__ */ g(Dt, { scope: e.__scopeSelect, children: /* @__PURE__ */ g(Pe.Slot, { scope: e.__scopeSelect, children: /* @__PURE__ */ g("div", { children: e.children }) }) }),
        i
      ) : null;
    }
    return /* @__PURE__ */ g(Bt, { ...e, ref: n });
  }
);
kt.displayName = ne;
var W = 10, [Dt, Q] = ue(ne), Rr = "SelectContentImpl", Bt = c.forwardRef(
  (e, n) => {
    const {
      __scopeSelect: t,
      position: r = "item-aligned",
      onCloseAutoFocus: a,
      onEscapeKeyDown: i,
      onPointerDownOutside: s,
      //
      // PopperContent props
      side: o,
      sideOffset: u,
      align: d,
      alignOffset: p,
      arrowPadding: m,
      collisionBoundary: S,
      collisionPadding: w,
      sticky: b,
      hideWhenDetached: l,
      avoidCollisions: f,
      //
      ...y
    } = e, v = Z(ne, t), [h, x] = c.useState(null), [P, _] = c.useState(null), C = B(n, (E) => x(E)), [O, L] = c.useState(null), [F, U] = c.useState(
      null
    ), K = Te(t), [Y, G] = c.useState(!1), j = c.useRef(!1);
    c.useEffect(() => {
      if (h) return Bn(h);
    }, [h]), Pn();
    const k = c.useCallback(
      (E) => {
        const [N, ...D] = K().map((I) => I.ref.current), [R] = D.slice(-1), T = document.activeElement;
        for (const I of E)
          if (I === T || (I == null || I.scrollIntoView({ block: "nearest" }), I === N && P && (P.scrollTop = 0), I === R && P && (P.scrollTop = P.scrollHeight), I == null || I.focus(), document.activeElement !== T)) return;
      },
      [K, P]
    ), H = c.useCallback(
      () => k([O, h]),
      [k, O, h]
    );
    c.useEffect(() => {
      Y && H();
    }, [Y, H]);
    const { onOpenChange: $, triggerPointerDownPosRef: X } = v;
    c.useEffect(() => {
      if (h) {
        let E = { x: 0, y: 0 };
        const N = (R) => {
          var T, I;
          E = {
            x: Math.abs(Math.round(R.pageX) - (((T = X.current) == null ? void 0 : T.x) ?? 0)),
            y: Math.abs(Math.round(R.pageY) - (((I = X.current) == null ? void 0 : I.y) ?? 0))
          };
        }, D = (R) => {
          E.x <= 10 && E.y <= 10 ? R.preventDefault() : h.contains(R.target) || $(!1), document.removeEventListener("pointermove", N), X.current = null;
        };
        return X.current !== null && (document.addEventListener("pointermove", N), document.addEventListener("pointerup", D, { capture: !0, once: !0 })), () => {
          document.removeEventListener("pointermove", N), document.removeEventListener("pointerup", D, { capture: !0 });
        };
      }
    }, [h, $, X]), c.useEffect(() => {
      const E = () => $(!1);
      return window.addEventListener("blur", E), window.addEventListener("resize", E), () => {
        window.removeEventListener("blur", E), window.removeEventListener("resize", E);
      };
    }, [$]);
    const [Re, he] = Qt((E) => {
      const N = K().filter((T) => !T.disabled), D = N.find((T) => T.ref.current === document.activeElement), R = Jt(N, E, D);
      R && setTimeout(() => R.ref.current.focus());
    }), Ne = c.useCallback(
      (E, N, D) => {
        const R = !j.current && !D;
        (v.value !== void 0 && v.value === N || R) && (L(E), R && (j.current = !0));
      },
      [v.value]
    ), Oe = c.useCallback(() => h == null ? void 0 : h.focus(), [h]), oe = c.useCallback(
      (E, N, D) => {
        const R = !j.current && !D;
        (v.value !== void 0 && v.value === N || R) && U(E);
      },
      [v.value]
    ), me = r === "popper" ? Ke : Ft, de = me === Ke ? {
      side: o,
      sideOffset: u,
      align: d,
      alignOffset: p,
      arrowPadding: m,
      collisionBoundary: S,
      collisionPadding: w,
      sticky: b,
      hideWhenDetached: l,
      avoidCollisions: f
    } : {};
    return /* @__PURE__ */ g(
      Dt,
      {
        scope: t,
        content: h,
        viewport: P,
        onViewportChange: _,
        itemRefCallback: Ne,
        selectedItem: O,
        onItemLeave: Oe,
        itemTextRefCallback: oe,
        focusSelectedItem: H,
        selectedItemText: F,
        position: r,
        isPositioned: Y,
        searchRef: Re,
        children: /* @__PURE__ */ g(It, { as: cn, allowPinchZoom: !0, children: /* @__PURE__ */ g(
          mt,
          {
            asChild: !0,
            trapped: v.open,
            onMountAutoFocus: (E) => {
              E.preventDefault();
            },
            onUnmountAutoFocus: A(a, (E) => {
              var N;
              (N = v.trigger) == null || N.focus({ preventScroll: !0 }), E.preventDefault();
            }),
            children: /* @__PURE__ */ g(
              vt,
              {
                asChild: !0,
                disableOutsidePointerEvents: !0,
                onEscapeKeyDown: i,
                onPointerDownOutside: s,
                onFocusOutside: (E) => E.preventDefault(),
                onDismiss: () => v.onOpenChange(!1),
                children: /* @__PURE__ */ g(
                  me,
                  {
                    role: "listbox",
                    id: v.contentId,
                    "data-state": v.open ? "open" : "closed",
                    dir: v.dir,
                    onContextMenu: (E) => E.preventDefault(),
                    ...y,
                    ...de,
                    onPlaced: () => G(!0),
                    ref: C,
                    style: {
                      // flex layout so we can place the scroll buttons properly
                      display: "flex",
                      flexDirection: "column",
                      // reset the outline by default as the content MAY get focused
                      outline: "none",
                      ...y.style
                    },
                    onKeyDown: A(y.onKeyDown, (E) => {
                      const N = E.ctrlKey || E.altKey || E.metaKey;
                      if (E.key === "Tab" && E.preventDefault(), !N && E.key.length === 1 && he(E.key), ["ArrowUp", "ArrowDown", "Home", "End"].includes(E.key)) {
                        let R = K().filter((T) => !T.disabled).map((T) => T.ref.current);
                        if (["ArrowUp", "End"].includes(E.key) && (R = R.slice().reverse()), ["ArrowUp", "ArrowDown"].includes(E.key)) {
                          const T = E.target, I = R.indexOf(T);
                          R = R.slice(I + 1);
                        }
                        setTimeout(() => k(R)), E.preventDefault();
                      }
                    })
                  }
                )
              }
            )
          }
        ) })
      }
    );
  }
);
Bt.displayName = Rr;
var Nr = "SelectItemAlignedPosition", Ft = c.forwardRef((e, n) => {
  const { __scopeSelect: t, onPlaced: r, ...a } = e, i = Z(ne, t), s = Q(ne, t), [o, u] = c.useState(null), [d, p] = c.useState(null), m = B(n, (C) => p(C)), S = Te(t), w = c.useRef(!1), b = c.useRef(!0), { viewport: l, selectedItem: f, selectedItemText: y, focusSelectedItem: v } = s, h = c.useCallback(() => {
    if (i.trigger && i.valueNode && o && d && l && f && y) {
      const C = i.trigger.getBoundingClientRect(), O = d.getBoundingClientRect(), L = i.valueNode.getBoundingClientRect(), F = y.getBoundingClientRect();
      if (i.dir !== "rtl") {
        const T = F.left - O.left, I = L.left - T, J = C.left - I, ee = C.width + J, Ae = Math.max(ee, O.width), Me = window.innerWidth - W, _e = Qe(I, [
          W,
          // Prevents the content from going off the starting edge of the
          // viewport. It may still go off the ending edge, but this can be
          // controlled by the user since they may want to manage overflow in a
          // specific way.
          // https://github.com/radix-ui/primitives/issues/2049
          Math.max(W, Me - Ae)
        ]);
        o.style.minWidth = ee + "px", o.style.left = _e + "px";
      } else {
        const T = O.right - F.right, I = window.innerWidth - L.right - T, J = window.innerWidth - C.right - I, ee = C.width + J, Ae = Math.max(ee, O.width), Me = window.innerWidth - W, _e = Qe(I, [
          W,
          Math.max(W, Me - Ae)
        ]);
        o.style.minWidth = ee + "px", o.style.right = _e + "px";
      }
      const U = S(), K = window.innerHeight - W * 2, Y = l.scrollHeight, G = window.getComputedStyle(d), j = parseInt(G.borderTopWidth, 10), k = parseInt(G.paddingTop, 10), H = parseInt(G.borderBottomWidth, 10), $ = parseInt(G.paddingBottom, 10), X = j + k + Y + $ + H, Re = Math.min(f.offsetHeight * 5, X), he = window.getComputedStyle(l), Ne = parseInt(he.paddingTop, 10), Oe = parseInt(he.paddingBottom, 10), oe = C.top + C.height / 2 - W, me = K - oe, de = f.offsetHeight / 2, E = f.offsetTop + de, N = j + k + E, D = X - N;
      if (N <= oe) {
        const T = U.length > 0 && f === U[U.length - 1].ref.current;
        o.style.bottom = "0px";
        const I = d.clientHeight - l.offsetTop - l.offsetHeight, J = Math.max(
          me,
          de + // viewport might have padding bottom, include it to avoid a scrollable viewport
          (T ? Oe : 0) + I + H
        ), ee = N + J;
        o.style.height = ee + "px";
      } else {
        const T = U.length > 0 && f === U[0].ref.current;
        o.style.top = "0px";
        const J = Math.max(
          oe,
          j + l.offsetTop + // viewport might have padding top, include it to avoid a scrollable viewport
          (T ? Ne : 0) + de
        ) + D;
        o.style.height = J + "px", l.scrollTop = N - oe + l.offsetTop;
      }
      o.style.margin = `${W}px 0`, o.style.minHeight = Re + "px", o.style.maxHeight = K + "px", r == null || r(), requestAnimationFrame(() => w.current = !0);
    }
  }, [
    S,
    i.trigger,
    i.valueNode,
    o,
    d,
    l,
    f,
    y,
    i.dir,
    r
  ]);
  z(() => h(), [h]);
  const [x, P] = c.useState();
  z(() => {
    d && P(window.getComputedStyle(d).zIndex);
  }, [d]);
  const _ = c.useCallback(
    (C) => {
      C && b.current === !0 && (h(), v == null || v(), b.current = !1);
    },
    [h, v]
  );
  return /* @__PURE__ */ g(
    Ar,
    {
      scope: t,
      contentWrapper: o,
      shouldExpandOnScrollRef: w,
      onScrollButtonChange: _,
      children: /* @__PURE__ */ g(
        "div",
        {
          ref: u,
          style: {
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            zIndex: x
          },
          children: /* @__PURE__ */ g(
            M.div,
            {
              ...a,
              ref: m,
              style: {
                // When we get the height of the content, it includes borders. If we were to set
                // the height without having `boxSizing: 'border-box'` it would be too big.
                boxSizing: "border-box",
                // We need to ensure the content doesn't get taller than the wrapper
                maxHeight: "100%",
                ...a.style
              }
            }
          )
        }
      )
    }
  );
});
Ft.displayName = Nr;
var Or = "SelectPopperPosition", Ke = c.forwardRef((e, n) => {
  const {
    __scopeSelect: t,
    align: r = "start",
    collisionPadding: a = W,
    ...i
  } = e, s = Ie(t);
  return /* @__PURE__ */ g(
    dn,
    {
      ...s,
      ...i,
      ref: n,
      align: r,
      collisionPadding: a,
      style: {
        // Ensure border-box for floating-ui calculations
        boxSizing: "border-box",
        ...i.style,
        "--radix-select-content-transform-origin": "var(--radix-popper-transform-origin)",
        "--radix-select-content-available-width": "var(--radix-popper-available-width)",
        "--radix-select-content-available-height": "var(--radix-popper-available-height)",
        "--radix-select-trigger-width": "var(--radix-popper-anchor-width)",
        "--radix-select-trigger-height": "var(--radix-popper-anchor-height)"
      }
    }
  );
});
Ke.displayName = Or;
var [Ar, qe] = ue(ne, {}), Ge = "SelectViewport", Wt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, nonce: r, ...a } = e, i = Q(Ge, t), s = qe(Ge, t), o = B(n, i.onViewportChange), u = c.useRef(0);
    return /* @__PURE__ */ le(Ye, { children: [
      /* @__PURE__ */ g(
        "style",
        {
          dangerouslySetInnerHTML: {
            __html: "[data-radix-select-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch;}[data-radix-select-viewport]::-webkit-scrollbar{display:none}"
          },
          nonce: r
        }
      ),
      /* @__PURE__ */ g(Pe.Slot, { scope: t, children: /* @__PURE__ */ g(
        M.div,
        {
          "data-radix-select-viewport": "",
          role: "presentation",
          ...a,
          ref: o,
          style: {
            // we use position: 'relative' here on the `viewport` so that when we call
            // `selectedItem.offsetTop` in calculations, the offset is relative to the viewport
            // (independent of the scrollUpButton).
            position: "relative",
            flex: 1,
            // Viewport should only be scrollable in the vertical direction.
            // This won't work in vertical writing modes, so we'll need to
            // revisit this if/when that is supported
            // https://developer.chrome.com/blog/vertical-form-controls
            overflow: "hidden auto",
            ...a.style
          },
          onScroll: A(a.onScroll, (d) => {
            const p = d.currentTarget, { contentWrapper: m, shouldExpandOnScrollRef: S } = s;
            if (S != null && S.current && m) {
              const w = Math.abs(u.current - p.scrollTop);
              if (w > 0) {
                const b = window.innerHeight - W * 2, l = parseFloat(m.style.minHeight), f = parseFloat(m.style.height), y = Math.max(l, f);
                if (y < b) {
                  const v = y + w, h = Math.min(b, v), x = v - h;
                  m.style.height = h + "px", m.style.bottom === "0px" && (p.scrollTop = x > 0 ? x : 0, m.style.justifyContent = "flex-end");
                }
              }
            }
            u.current = p.scrollTop;
          })
        }
      ) })
    ] });
  }
);
Wt.displayName = Ge;
var Ht = "SelectGroup", [Mr, _r] = ue(Ht), Vt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e, a = Xe();
    return /* @__PURE__ */ g(Mr, { scope: t, id: a, children: /* @__PURE__ */ g(M.div, { role: "group", "aria-labelledby": a, ...r, ref: n }) });
  }
);
Vt.displayName = Ht;
var Ut = "SelectLabel", Kt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e, a = _r(Ut, t);
    return /* @__PURE__ */ g(M.div, { id: a.id, ...r, ref: n });
  }
);
Kt.displayName = Ut;
var Ce = "SelectItem", [Lr, Gt] = ue(Ce), jt = c.forwardRef(
  (e, n) => {
    const {
      __scopeSelect: t,
      value: r,
      disabled: a = !1,
      textValue: i,
      ...s
    } = e, o = Z(Ce, t), u = Q(Ce, t), d = o.value === r, [p, m] = c.useState(i ?? ""), [S, w] = c.useState(!1), b = B(
      n,
      (v) => {
        var h;
        return (h = u.itemRefCallback) == null ? void 0 : h.call(u, v, r, a);
      }
    ), l = Xe(), f = c.useRef("touch"), y = () => {
      a || (o.onValueChange(r), o.onOpenChange(!1));
    };
    if (r === "")
      throw new Error(
        "A <Select.Item /> must have a value prop that is not an empty string. This is because the Select value can be set to an empty string to clear the selection and show the placeholder."
      );
    return /* @__PURE__ */ g(
      Lr,
      {
        scope: t,
        value: r,
        disabled: a,
        textId: l,
        isSelected: d,
        onItemTextChange: c.useCallback((v) => {
          m((h) => h || ((v == null ? void 0 : v.textContent) ?? "").trim());
        }, []),
        children: /* @__PURE__ */ g(
          Pe.ItemSlot,
          {
            scope: t,
            value: r,
            disabled: a,
            textValue: p,
            children: /* @__PURE__ */ g(
              M.div,
              {
                role: "option",
                "aria-labelledby": l,
                "data-highlighted": S ? "" : void 0,
                "aria-selected": d && S,
                "data-state": d ? "checked" : "unchecked",
                "aria-disabled": a || void 0,
                "data-disabled": a ? "" : void 0,
                tabIndex: a ? void 0 : -1,
                ...s,
                ref: b,
                onFocus: A(s.onFocus, () => w(!0)),
                onBlur: A(s.onBlur, () => w(!1)),
                onClick: A(s.onClick, () => {
                  f.current !== "mouse" && y();
                }),
                onPointerUp: A(s.onPointerUp, () => {
                  f.current === "mouse" && y();
                }),
                onPointerDown: A(s.onPointerDown, (v) => {
                  f.current = v.pointerType;
                }),
                onPointerMove: A(s.onPointerMove, (v) => {
                  var h;
                  f.current = v.pointerType, a ? (h = u.onItemLeave) == null || h.call(u) : f.current === "mouse" && v.currentTarget.focus({ preventScroll: !0 });
                }),
                onPointerLeave: A(s.onPointerLeave, (v) => {
                  var h;
                  v.currentTarget === document.activeElement && ((h = u.onItemLeave) == null || h.call(u));
                }),
                onKeyDown: A(s.onKeyDown, (v) => {
                  var x;
                  ((x = u.searchRef) == null ? void 0 : x.current) !== "" && v.key === " " || (br.includes(v.key) && y(), v.key === " " && v.preventDefault());
                })
              }
            )
          }
        )
      }
    );
  }
);
jt.displayName = Ce;
var fe = "SelectItemText", $t = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, className: r, style: a, ...i } = e, s = Z(fe, t), o = Q(fe, t), u = Gt(fe, t), d = Pr(fe, t), [p, m] = c.useState(null), S = B(
      n,
      (y) => m(y),
      u.onItemTextChange,
      (y) => {
        var v;
        return (v = o.itemTextRefCallback) == null ? void 0 : v.call(o, y, u.value, u.disabled);
      }
    ), w = p == null ? void 0 : p.textContent, b = c.useMemo(
      () => /* @__PURE__ */ g("option", { value: u.value, disabled: u.disabled, children: w }, u.value),
      [u.disabled, u.value, w]
    ), { onNativeOptionAdd: l, onNativeOptionRemove: f } = d;
    return z(() => (l(b), () => f(b)), [l, f, b]), /* @__PURE__ */ le(Ye, { children: [
      /* @__PURE__ */ g(M.span, { id: u.textId, ...i, ref: S }),
      u.isSelected && s.valueNode && !s.valueNodeHasChildren ? dt.createPortal(i.children, s.valueNode) : null
    ] });
  }
);
$t.displayName = fe;
var zt = "SelectItemIndicator", kr = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e;
    return Gt(zt, t).isSelected ? /* @__PURE__ */ g(M.span, { "aria-hidden": !0, ...r, ref: n }) : null;
  }
);
kr.displayName = zt;
var je = "SelectScrollUpButton", Dr = c.forwardRef((e, n) => {
  const t = Q(je, e.__scopeSelect), r = qe(je, e.__scopeSelect), [a, i] = c.useState(!1), s = B(n, r.onScrollButtonChange);
  return z(() => {
    if (t.viewport && t.isPositioned) {
      let o = function() {
        const d = u.scrollTop > 0;
        i(d);
      };
      const u = t.viewport;
      return o(), u.addEventListener("scroll", o), () => u.removeEventListener("scroll", o);
    }
  }, [t.viewport, t.isPositioned]), a ? /* @__PURE__ */ g(
    Yt,
    {
      ...e,
      ref: s,
      onAutoScroll: () => {
        const { viewport: o, selectedItem: u } = t;
        o && u && (o.scrollTop = o.scrollTop - u.offsetHeight);
      }
    }
  ) : null;
});
Dr.displayName = je;
var $e = "SelectScrollDownButton", Br = c.forwardRef((e, n) => {
  const t = Q($e, e.__scopeSelect), r = qe($e, e.__scopeSelect), [a, i] = c.useState(!1), s = B(n, r.onScrollButtonChange);
  return z(() => {
    if (t.viewport && t.isPositioned) {
      let o = function() {
        const d = u.scrollHeight - u.clientHeight, p = Math.ceil(u.scrollTop) < d;
        i(p);
      };
      const u = t.viewport;
      return o(), u.addEventListener("scroll", o), () => u.removeEventListener("scroll", o);
    }
  }, [t.viewport, t.isPositioned]), a ? /* @__PURE__ */ g(
    Yt,
    {
      ...e,
      ref: s,
      onAutoScroll: () => {
        const { viewport: o, selectedItem: u } = t;
        o && u && (o.scrollTop = o.scrollTop + u.offsetHeight);
      }
    }
  ) : null;
});
Br.displayName = $e;
var Yt = c.forwardRef((e, n) => {
  const { __scopeSelect: t, onAutoScroll: r, ...a } = e, i = Q("SelectScrollButton", t), s = c.useRef(null), o = Te(t), u = c.useCallback(() => {
    s.current !== null && (window.clearInterval(s.current), s.current = null);
  }, []);
  return c.useEffect(() => () => u(), [u]), z(() => {
    var p;
    const d = o().find((m) => m.ref.current === document.activeElement);
    (p = d == null ? void 0 : d.ref.current) == null || p.scrollIntoView({ block: "nearest" });
  }, [o]), /* @__PURE__ */ g(
    M.div,
    {
      "aria-hidden": !0,
      ...a,
      ref: n,
      style: { flexShrink: 0, ...a.style },
      onPointerDown: A(a.onPointerDown, () => {
        s.current === null && (s.current = window.setInterval(r, 50));
      }),
      onPointerMove: A(a.onPointerMove, () => {
        var d;
        (d = i.onItemLeave) == null || d.call(i), s.current === null && (s.current = window.setInterval(r, 50));
      }),
      onPointerLeave: A(a.onPointerLeave, () => {
        u();
      })
    }
  );
}), Fr = "SelectSeparator", Xt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e;
    return /* @__PURE__ */ g(M.div, { "aria-hidden": !0, ...r, ref: n });
  }
);
Xt.displayName = Fr;
var ze = "SelectArrow", Wr = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e, a = Ie(t), i = Z(ze, t), s = Q(ze, t);
    return i.open && s.position === "popper" ? /* @__PURE__ */ g(fn, { ...a, ...r, ref: n }) : null;
  }
);
Wr.displayName = ze;
function qt(e) {
  return e === "" || e === void 0;
}
var Zt = c.forwardRef(
  (e, n) => {
    const { value: t, ...r } = e, a = c.useRef(null), i = B(n, a), s = hn(t);
    return c.useEffect(() => {
      const o = a.current, u = window.HTMLSelectElement.prototype, p = Object.getOwnPropertyDescriptor(
        u,
        "value"
      ).set;
      if (s !== t && p) {
        const m = new Event("change", { bubbles: !0 });
        p.call(o, t), o.dispatchEvent(m);
      }
    }, [s, t]), /* @__PURE__ */ g(pn, { asChild: !0, children: /* @__PURE__ */ g("select", { ...r, ref: i, defaultValue: t }) });
  }
);
Zt.displayName = "BubbleSelect";
function Qt(e) {
  const n = pe(e), t = c.useRef(""), r = c.useRef(0), a = c.useCallback(
    (s) => {
      const o = t.current + s;
      n(o), function u(d) {
        t.current = d, window.clearTimeout(r.current), d !== "" && (r.current = window.setTimeout(() => u(""), 1e3));
      }(o);
    },
    [n]
  ), i = c.useCallback(() => {
    t.current = "", window.clearTimeout(r.current);
  }, []);
  return c.useEffect(() => () => window.clearTimeout(r.current), []), [t, a, i];
}
function Jt(e, n, t) {
  const a = n.length > 1 && Array.from(n).every((d) => d === n[0]) ? n[0] : n, i = t ? e.indexOf(t) : -1;
  let s = Hr(e, Math.max(i, 0));
  a.length === 1 && (s = s.filter((d) => d !== t));
  const u = s.find(
    (d) => d.textValue.toLowerCase().startsWith(a.toLowerCase())
  );
  return u !== t ? u : void 0;
}
function Hr(e, n) {
  return e.map((t, r) => e[(n + r) % e.length]);
}
var Vr = Rt, Ur = Ot, Kr = Mt, Gr = _t, jr = Lt, $r = kt, zr = Wt, Yr = Vt, Xr = Kt, qr = jt, Zr = $t, Qr = Xt;
const Jr = Vr, eo = Kr, en = re(
  ({ className: e, hasError: n, children: t, ...r }, a) => /* @__PURE__ */ le(
    Ur,
    {
      ref: a,
      className: te(
        // Base
        "group/trigger flex h-s-6 w-full items-center justify-between truncate rounded-sm pl-sp-4 pr-sp-3 text-t1 antialiased outline-none transition",
        // Border
        "border border-action-stroke-input-primary-default hover:border-action-stroke-input-primary-hover",
        // Focus
        "focus:border-action-stroke-input-primary-active",
        // Active
        "data-[state=open]:border-action-stroke-input-primary-active",
        // Disabled
        "data-[disabled]:border-action-stroke-input-primary-disabled data-[disabled]:text-action-content-extra-disabled",
        // Error
        {
          "border-action-stroke-input-danger-active hover:border-action-stroke-input-danger-active focus:border-action-stroke-input-danger-active data-[state=open]:border-action-stroke-input-danger-active": n
        },
        e
      ),
      ...r,
      children: [
        /* @__PURE__ */ g("span", { className: "truncate", children: t }),
        /* @__PURE__ */ g(
          Gr,
          {
            asChild: !0,
            className: "shrink-0 transition-transform group-data-[state=open]/trigger:rotate-180",
            children: /* @__PURE__ */ g(
              "svg",
              {
                xmlns: "http://www.w3.org/2000/svg",
                width: "20",
                height: "20",
                viewBox: "0 0 20 20",
                fill: "none",
                children: /* @__PURE__ */ g(
                  "path",
                  {
                    d: "M4.5 8.50012L8.86867 12.8687C9.26469 13.2647 9.46271 13.4627 9.69104 13.5369C9.89188 13.6022 10.1082 13.6022 10.3091 13.5369C10.5374 13.4627 10.7354 13.2647 11.1314 12.8687L15.5 8.49994",
                    stroke: "currentColor",
                    strokeWidth: "1.5",
                    strokeLinecap: "square"
                  }
                )
              }
            )
          }
        )
      ]
    }
  )
);
en.displayName = "SelectTrigger";
const tn = re(
  ({
    className: e,
    position: n = "popper",
    children: t,
    sideOffset: r = 4,
    collisionPadding: a = 10,
    ...i
  }, s) => /* @__PURE__ */ g(jr, { children: /* @__PURE__ */ g(
    $r,
    {
      ref: s,
      className: te(
        // Base
        "max-h-[var(--radix-select-content-available-height)] rounded-lg bg-bg-secondary p-sp-2 text-t1 antialiased shadow-xl",
        // Animation
        "will-change-[transform,opacity]",
        "data-[state=closed]:animate-hide",
        "data-[side=bottom]:animate-slide-down-and-fade data-[side=top]:animate-slide-up-and-fade",
        e
      ),
      sideOffset: r,
      position: n,
      collisionPadding: a,
      ...i,
      children: /* @__PURE__ */ g(
        zr,
        {
          className: te("flex flex-col gap-sp-1", {
            "h-[var(--radix-select-trigger-height)] w-full min-w-[calc(var(--radix-select-trigger-width)-var(--fui-sp-2)*2)]": n === "popper"
          }),
          children: t
        }
      )
    }
  ) })
);
tn.displayName = "SelectContent";
const to = re(
  ({ className: e, children: n, ...t }, r) => /* @__PURE__ */ g(
    qr,
    {
      ref: r,
      className: te(
        // Base
        "cursor-pointer select-none gap-sp-3 rounded-md px-sp-4 py-sp-3 outline-none transition-colors",
        // Hover
        "hover:bg-action-primary-1 data-[highlighted]:bg-action-primary-1",
        // Active
        "data-[state=checked]:bg-action-primary-1 data-[state=checked]:text-action-primary-0-2",
        // Disabled
        "data-[disabled]:cursor-default data-[disabled]:bg-transparent data-[disabled]:text-action-disable-0",
        e
      ),
      ...t,
      children: /* @__PURE__ */ g(Zr, { children: n })
    }
  )
);
to.displayName = "SelectItem";
const no = re(
  ({ className: e, ...n }, t) => /* @__PURE__ */ g(
    Yr,
    {
      ref: t,
      className: te("flex flex-col gap-sp-1", e),
      ...n
    }
  )
);
no.displayName = "SelectGroup";
const ro = re(
  ({ className: e, ...n }, t) => /* @__PURE__ */ g(
    Xr,
    {
      ref: t,
      className: te("px-sp-4 py-sp-3 text-h5", e),
      ...n
    }
  )
);
ro.displayName = "SelectGroupLabel";
const oo = re(
  ({ className: e, ...n }, t) => /* @__PURE__ */ g(
    Qr,
    {
      ref: t,
      className: te("mx-sp-4 my-sp-2 h-[1px] bg-el-stroke-primary", e),
      ...n
    }
  )
);
oo.displayName = "SelectSeparator";
const ao = re(
  ({
    hasError: e,
    children: n,
    placeholder: t,
    onCloseAutoFocus: r,
    onEscapeKeyDown: a,
    onPointerDownOutside: i,
    id: s,
    contentClasses: o,
    triggerClasses: u,
    ...d
  }, p) => /* @__PURE__ */ le(Jr, { ...d, children: [
    /* @__PURE__ */ g(
      en,
      {
        hasError: e,
        id: s,
        ref: p,
        className: u,
        children: /* @__PURE__ */ g(eo, { placeholder: t })
      }
    ),
    /* @__PURE__ */ g(
      tn,
      {
        className: o,
        onCloseAutoFocus: r,
        onEscapeKeyDown: a,
        onPointerDownOutside: i,
        children: n
      }
    )
  ] })
);
ao.displayName = "Select";
export {
  ao as Select,
  no as SelectGroup,
  ro as SelectGroupLabel,
  to as SelectItem,
  oo as SelectSeparator
};
