import * as A from "react";
import n from "react";
import { c as O } from "./index-CVQolMUX.js";
import { u as S, S as I } from "./index-CCavb-1K.js";
import { jsx as u } from "react/jsx-runtime";
function g(l) {
  const i = l + "CollectionProvider", [N, _] = O(i), [v, f] = N(
    i,
    { collectionRef: { current: null }, itemMap: /* @__PURE__ */ new Map() }
  ), m = (c) => {
    const { scope: e, children: s } = c, t = n.useRef(null), o = n.useRef(/* @__PURE__ */ new Map()).current;
    return /* @__PURE__ */ u(v, { scope: e, itemMap: o, collectionRef: t, children: s });
  };
  m.displayName = i;
  const d = l + "CollectionSlot", p = n.forwardRef(
    (c, e) => {
      const { scope: s, children: t } = c, o = f(d, s), r = S(e, o.collectionRef);
      return /* @__PURE__ */ u(I, { ref: r, children: t });
    }
  );
  p.displayName = d;
  const C = l + "CollectionItemSlot", R = "data-radix-collection-item", x = n.forwardRef(
    (c, e) => {
      const { scope: s, children: t, ...o } = c, r = n.useRef(null), M = S(e, r), a = f(C, s);
      return n.useEffect(() => (a.itemMap.set(r, { ref: r, ...o }), () => void a.itemMap.delete(r))), /* @__PURE__ */ u(I, { [R]: "", ref: M, children: t });
    }
  );
  x.displayName = C;
  function E(c) {
    const e = f(l + "CollectionConsumer", c);
    return n.useCallback(() => {
      const t = e.collectionRef.current;
      if (!t) return [];
      const o = Array.from(t.querySelectorAll(`[${R}]`));
      return Array.from(e.itemMap.values()).sort(
        (a, T) => o.indexOf(a.ref.current) - o.indexOf(T.ref.current)
      );
    }, [e.collectionRef, e.itemMap]);
  }
  return [
    { Provider: m, Slot: p, ItemSlot: x },
    E,
    _
  ];
}
var y = A.createContext(void 0);
function h(l) {
  const i = A.useContext(y);
  return l || i || "ltr";
}
export {
  g as c,
  h as u
};
