import * as C from "react";
import { useLayoutEffect as Ae, useEffect as Re } from "react";
import { u as Yt, c as Ce, a as $t } from "./index-CVQolMUX.js";
import * as Pe from "react-dom";
import { P as pt } from "./index-u2zzFwMH.js";
import { jsx as B } from "react/jsx-runtime";
import { u as jt } from "./index-CCavb-1K.js";
import { u as Oe } from "./index-BVAIRv_c.js";
function Vn(t, e = globalThis == null ? void 0 : globalThis.document) {
  const n = Yt(t);
  C.useEffect(() => {
    const o = (i) => {
      i.key === "Escape" && n(i);
    };
    return e.addEventListener("keydown", o, { capture: !0 }), () => e.removeEventListener("keydown", o, { capture: !0 });
  }, [n, e]);
}
const Ee = ["top", "right", "bottom", "left"], _ = Math.min, N = Math.max, at = Math.round, ct = Math.floor, K = (t) => ({
  x: t,
  y: t
}), Se = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, Le = {
  start: "end",
  end: "start"
};
function gt(t, e, n) {
  return N(t, _(e, n));
}
function Y(t, e) {
  return typeof t == "function" ? t(e) : t;
}
function j(t) {
  return t.split("-")[0];
}
function J(t) {
  return t.split("-")[1];
}
function xt(t) {
  return t === "x" ? "y" : "x";
}
function yt(t) {
  return t === "y" ? "height" : "width";
}
function Q(t) {
  return ["top", "bottom"].includes(j(t)) ? "y" : "x";
}
function vt(t) {
  return xt(Q(t));
}
function Te(t, e, n) {
  n === void 0 && (n = !1);
  const o = J(t), i = vt(t), r = yt(i);
  let s = i === "x" ? o === (n ? "end" : "start") ? "right" : "left" : o === "start" ? "bottom" : "top";
  return e.reference[r] > e.floating[r] && (s = ft(s)), [s, ft(s)];
}
function Me(t) {
  const e = ft(t);
  return [wt(t), e, wt(e)];
}
function wt(t) {
  return t.replace(/start|end/g, (e) => Le[e]);
}
function De(t, e, n) {
  const o = ["left", "right"], i = ["right", "left"], r = ["top", "bottom"], s = ["bottom", "top"];
  switch (t) {
    case "top":
    case "bottom":
      return n ? e ? i : o : e ? o : i;
    case "left":
    case "right":
      return e ? r : s;
    default:
      return [];
  }
}
function ke(t, e, n, o) {
  const i = J(t);
  let r = De(j(t), n === "start", o);
  return i && (r = r.map((s) => s + "-" + i), e && (r = r.concat(r.map(wt)))), r;
}
function ft(t) {
  return t.replace(/left|right|bottom|top/g, (e) => Se[e]);
}
function $e(t) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...t
  };
}
function Xt(t) {
  return typeof t != "number" ? $e(t) : {
    top: t,
    right: t,
    bottom: t,
    left: t
  };
}
function ut(t) {
  const {
    x: e,
    y: n,
    width: o,
    height: i
  } = t;
  return {
    width: o,
    height: i,
    top: n,
    left: e,
    right: e + o,
    bottom: n + i,
    x: e,
    y: n
  };
}
function Nt(t, e, n) {
  let {
    reference: o,
    floating: i
  } = t;
  const r = Q(e), s = vt(e), l = yt(s), c = j(e), a = r === "y", d = o.x + o.width / 2 - i.width / 2, u = o.y + o.height / 2 - i.height / 2, m = o[l] / 2 - i[l] / 2;
  let f;
  switch (c) {
    case "top":
      f = {
        x: d,
        y: o.y - i.height
      };
      break;
    case "bottom":
      f = {
        x: d,
        y: o.y + o.height
      };
      break;
    case "right":
      f = {
        x: o.x + o.width,
        y: u
      };
      break;
    case "left":
      f = {
        x: o.x - i.width,
        y: u
      };
      break;
    default:
      f = {
        x: o.x,
        y: o.y
      };
  }
  switch (J(e)) {
    case "start":
      f[s] -= m * (n && a ? -1 : 1);
      break;
    case "end":
      f[s] += m * (n && a ? -1 : 1);
      break;
  }
  return f;
}
const Ne = async (t, e, n) => {
  const {
    placement: o = "bottom",
    strategy: i = "absolute",
    middleware: r = [],
    platform: s
  } = n, l = r.filter(Boolean), c = await (s.isRTL == null ? void 0 : s.isRTL(e));
  let a = await s.getElementRects({
    reference: t,
    floating: e,
    strategy: i
  }), {
    x: d,
    y: u
  } = Nt(a, o, c), m = o, f = {}, p = 0;
  for (let h = 0; h < l.length; h++) {
    const {
      name: w,
      fn: g
    } = l[h], {
      x,
      y: b,
      data: y,
      reset: v
    } = await g({
      x: d,
      y: u,
      initialPlacement: o,
      placement: m,
      strategy: i,
      middlewareData: f,
      rects: a,
      platform: s,
      elements: {
        reference: t,
        floating: e
      }
    });
    d = x ?? d, u = b ?? u, f = {
      ...f,
      [w]: {
        ...f[w],
        ...y
      }
    }, v && p <= 50 && (p++, typeof v == "object" && (v.placement && (m = v.placement), v.rects && (a = v.rects === !0 ? await s.getElementRects({
      reference: t,
      floating: e,
      strategy: i
    }) : v.rects), {
      x: d,
      y: u
    } = Nt(a, m, c)), h = -1);
  }
  return {
    x: d,
    y: u,
    placement: m,
    strategy: i,
    middlewareData: f
  };
};
async function nt(t, e) {
  var n;
  e === void 0 && (e = {});
  const {
    x: o,
    y: i,
    platform: r,
    rects: s,
    elements: l,
    strategy: c
  } = t, {
    boundary: a = "clippingAncestors",
    rootBoundary: d = "viewport",
    elementContext: u = "floating",
    altBoundary: m = !1,
    padding: f = 0
  } = Y(e, t), p = Xt(f), w = l[m ? u === "floating" ? "reference" : "floating" : u], g = ut(await r.getClippingRect({
    element: (n = await (r.isElement == null ? void 0 : r.isElement(w))) == null || n ? w : w.contextElement || await (r.getDocumentElement == null ? void 0 : r.getDocumentElement(l.floating)),
    boundary: a,
    rootBoundary: d,
    strategy: c
  })), x = u === "floating" ? {
    x: o,
    y: i,
    width: s.floating.width,
    height: s.floating.height
  } : s.reference, b = await (r.getOffsetParent == null ? void 0 : r.getOffsetParent(l.floating)), y = await (r.isElement == null ? void 0 : r.isElement(b)) ? await (r.getScale == null ? void 0 : r.getScale(b)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, v = ut(r.convertOffsetParentRelativeRectToViewportRelativeRect ? await r.convertOffsetParentRelativeRectToViewportRelativeRect({
    elements: l,
    rect: x,
    offsetParent: b,
    strategy: c
  }) : x);
  return {
    top: (g.top - v.top + p.top) / y.y,
    bottom: (v.bottom - g.bottom + p.bottom) / y.y,
    left: (g.left - v.left + p.left) / y.x,
    right: (v.right - g.right + p.right) / y.x
  };
}
const He = (t) => ({
  name: "arrow",
  options: t,
  async fn(e) {
    const {
      x: n,
      y: o,
      placement: i,
      rects: r,
      platform: s,
      elements: l,
      middlewareData: c
    } = e, {
      element: a,
      padding: d = 0
    } = Y(t, e) || {};
    if (a == null)
      return {};
    const u = Xt(d), m = {
      x: n,
      y: o
    }, f = vt(i), p = yt(f), h = await s.getDimensions(a), w = f === "y", g = w ? "top" : "left", x = w ? "bottom" : "right", b = w ? "clientHeight" : "clientWidth", y = r.reference[p] + r.reference[f] - m[f] - r.floating[p], v = m[f] - r.reference[f], A = await (s.getOffsetParent == null ? void 0 : s.getOffsetParent(a));
    let P = A ? A[b] : 0;
    (!P || !await (s.isElement == null ? void 0 : s.isElement(A))) && (P = l.floating[b] || r.floating[p]);
    const S = y / 2 - v / 2, F = P / 2 - h[p] / 2 - 1, D = _(u[g], F), T = _(u[x], F), E = D, k = P - h[p] - T, L = P / 2 - h[p] / 2 + S, O = gt(E, L, k), M = !c.arrow && J(i) != null && L !== O && r.reference[p] / 2 - (L < E ? D : T) - h[p] / 2 < 0, R = M ? L < E ? L - E : L - k : 0;
    return {
      [f]: m[f] + R,
      data: {
        [f]: O,
        centerOffset: L - O - R,
        ...M && {
          alignmentOffset: R
        }
      },
      reset: M
    };
  }
}), Fe = function(t) {
  return t === void 0 && (t = {}), {
    name: "flip",
    options: t,
    async fn(e) {
      var n, o;
      const {
        placement: i,
        middlewareData: r,
        rects: s,
        initialPlacement: l,
        platform: c,
        elements: a
      } = e, {
        mainAxis: d = !0,
        crossAxis: u = !0,
        fallbackPlacements: m,
        fallbackStrategy: f = "bestFit",
        fallbackAxisSideDirection: p = "none",
        flipAlignment: h = !0,
        ...w
      } = Y(t, e);
      if ((n = r.arrow) != null && n.alignmentOffset)
        return {};
      const g = j(i), x = j(l) === l, b = await (c.isRTL == null ? void 0 : c.isRTL(a.floating)), y = m || (x || !h ? [ft(l)] : Me(l));
      !m && p !== "none" && y.push(...ke(l, h, p, b));
      const v = [l, ...y], A = await nt(e, w), P = [];
      let S = ((o = r.flip) == null ? void 0 : o.overflows) || [];
      if (d && P.push(A[g]), u) {
        const E = Te(i, s, b);
        P.push(A[E[0]], A[E[1]]);
      }
      if (S = [...S, {
        placement: i,
        overflows: P
      }], !P.every((E) => E <= 0)) {
        var F, D;
        const E = (((F = r.flip) == null ? void 0 : F.index) || 0) + 1, k = v[E];
        if (k)
          return {
            data: {
              index: E,
              overflows: S
            },
            reset: {
              placement: k
            }
          };
        let L = (D = S.filter((O) => O.overflows[0] <= 0).sort((O, M) => O.overflows[1] - M.overflows[1])[0]) == null ? void 0 : D.placement;
        if (!L)
          switch (f) {
            case "bestFit": {
              var T;
              const O = (T = S.map((M) => [M.placement, M.overflows.filter((R) => R > 0).reduce((R, $) => R + $, 0)]).sort((M, R) => M[1] - R[1])[0]) == null ? void 0 : T[0];
              O && (L = O);
              break;
            }
            case "initialPlacement":
              L = l;
              break;
          }
        if (i !== L)
          return {
            reset: {
              placement: L
            }
          };
      }
      return {};
    }
  };
};
function Ht(t, e) {
  return {
    top: t.top - e.height,
    right: t.right - e.width,
    bottom: t.bottom - e.height,
    left: t.left - e.width
  };
}
function Ft(t) {
  return Ee.some((e) => t[e] >= 0);
}
const We = function(t) {
  return t === void 0 && (t = {}), {
    name: "hide",
    options: t,
    async fn(e) {
      const {
        rects: n
      } = e, {
        strategy: o = "referenceHidden",
        ...i
      } = Y(t, e);
      switch (o) {
        case "referenceHidden": {
          const r = await nt(e, {
            ...i,
            elementContext: "reference"
          }), s = Ht(r, n.reference);
          return {
            data: {
              referenceHiddenOffsets: s,
              referenceHidden: Ft(s)
            }
          };
        }
        case "escaped": {
          const r = await nt(e, {
            ...i,
            altBoundary: !0
          }), s = Ht(r, n.floating);
          return {
            data: {
              escapedOffsets: s,
              escaped: Ft(s)
            }
          };
        }
        default:
          return {};
      }
    }
  };
};
async function _e(t, e) {
  const {
    placement: n,
    platform: o,
    elements: i
  } = t, r = await (o.isRTL == null ? void 0 : o.isRTL(i.floating)), s = j(n), l = J(n), c = Q(n) === "y", a = ["left", "top"].includes(s) ? -1 : 1, d = r && c ? -1 : 1, u = Y(e, t);
  let {
    mainAxis: m,
    crossAxis: f,
    alignmentAxis: p
  } = typeof u == "number" ? {
    mainAxis: u,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...u
  };
  return l && typeof p == "number" && (f = l === "end" ? p * -1 : p), c ? {
    x: f * d,
    y: m * a
  } : {
    x: m * a,
    y: f * d
  };
}
const Be = function(t) {
  return t === void 0 && (t = 0), {
    name: "offset",
    options: t,
    async fn(e) {
      var n, o;
      const {
        x: i,
        y: r,
        placement: s,
        middlewareData: l
      } = e, c = await _e(e, t);
      return s === ((n = l.offset) == null ? void 0 : n.placement) && (o = l.arrow) != null && o.alignmentOffset ? {} : {
        x: i + c.x,
        y: r + c.y,
        data: {
          ...c,
          placement: s
        }
      };
    }
  };
}, Ve = function(t) {
  return t === void 0 && (t = {}), {
    name: "shift",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: o,
        placement: i
      } = e, {
        mainAxis: r = !0,
        crossAxis: s = !1,
        limiter: l = {
          fn: (w) => {
            let {
              x: g,
              y: x
            } = w;
            return {
              x: g,
              y: x
            };
          }
        },
        ...c
      } = Y(t, e), a = {
        x: n,
        y: o
      }, d = await nt(e, c), u = Q(j(i)), m = xt(u);
      let f = a[m], p = a[u];
      if (r) {
        const w = m === "y" ? "top" : "left", g = m === "y" ? "bottom" : "right", x = f + d[w], b = f - d[g];
        f = gt(x, f, b);
      }
      if (s) {
        const w = u === "y" ? "top" : "left", g = u === "y" ? "bottom" : "right", x = p + d[w], b = p - d[g];
        p = gt(x, p, b);
      }
      const h = l.fn({
        ...e,
        [m]: f,
        [u]: p
      });
      return {
        ...h,
        data: {
          x: h.x - n,
          y: h.y - o
        }
      };
    }
  };
}, ze = function(t) {
  return t === void 0 && (t = {}), {
    options: t,
    fn(e) {
      const {
        x: n,
        y: o,
        placement: i,
        rects: r,
        middlewareData: s
      } = e, {
        offset: l = 0,
        mainAxis: c = !0,
        crossAxis: a = !0
      } = Y(t, e), d = {
        x: n,
        y: o
      }, u = Q(i), m = xt(u);
      let f = d[m], p = d[u];
      const h = Y(l, e), w = typeof h == "number" ? {
        mainAxis: h,
        crossAxis: 0
      } : {
        mainAxis: 0,
        crossAxis: 0,
        ...h
      };
      if (c) {
        const b = m === "y" ? "height" : "width", y = r.reference[m] - r.floating[b] + w.mainAxis, v = r.reference[m] + r.reference[b] - w.mainAxis;
        f < y ? f = y : f > v && (f = v);
      }
      if (a) {
        var g, x;
        const b = m === "y" ? "width" : "height", y = ["top", "left"].includes(j(i)), v = r.reference[u] - r.floating[b] + (y && ((g = s.offset) == null ? void 0 : g[u]) || 0) + (y ? 0 : w.crossAxis), A = r.reference[u] + r.reference[b] + (y ? 0 : ((x = s.offset) == null ? void 0 : x[u]) || 0) - (y ? w.crossAxis : 0);
        p < v ? p = v : p > A && (p = A);
      }
      return {
        [m]: f,
        [u]: p
      };
    }
  };
}, Ie = function(t) {
  return t === void 0 && (t = {}), {
    name: "size",
    options: t,
    async fn(e) {
      const {
        placement: n,
        rects: o,
        platform: i,
        elements: r
      } = e, {
        apply: s = () => {
        },
        ...l
      } = Y(t, e), c = await nt(e, l), a = j(n), d = J(n), u = Q(n) === "y", {
        width: m,
        height: f
      } = o.floating;
      let p, h;
      a === "top" || a === "bottom" ? (p = a, h = d === (await (i.isRTL == null ? void 0 : i.isRTL(r.floating)) ? "start" : "end") ? "left" : "right") : (h = a, p = d === "end" ? "top" : "bottom");
      const w = f - c.top - c.bottom, g = m - c.left - c.right, x = _(f - c[p], w), b = _(m - c[h], g), y = !e.middlewareData.shift;
      let v = x, A = b;
      if (u ? A = d || y ? _(b, g) : g : v = d || y ? _(x, w) : w, y && !d) {
        const S = N(c.left, 0), F = N(c.right, 0), D = N(c.top, 0), T = N(c.bottom, 0);
        u ? A = m - 2 * (S !== 0 || F !== 0 ? S + F : N(c.left, c.right)) : v = f - 2 * (D !== 0 || T !== 0 ? D + T : N(c.top, c.bottom));
      }
      await s({
        ...e,
        availableWidth: A,
        availableHeight: v
      });
      const P = await i.getDimensions(r.floating);
      return m !== P.width || f !== P.height ? {
        reset: {
          rects: !0
        }
      } : {};
    }
  };
};
function tt(t) {
  return Kt(t) ? (t.nodeName || "").toLowerCase() : "#document";
}
function H(t) {
  var e;
  return (t == null || (e = t.ownerDocument) == null ? void 0 : e.defaultView) || window;
}
function X(t) {
  var e;
  return (e = (Kt(t) ? t.ownerDocument : t.document) || window.document) == null ? void 0 : e.documentElement;
}
function Kt(t) {
  return t instanceof Node || t instanceof H(t).Node;
}
function V(t) {
  return t instanceof Element || t instanceof H(t).Element;
}
function z(t) {
  return t instanceof HTMLElement || t instanceof H(t).HTMLElement;
}
function Wt(t) {
  return typeof ShadowRoot > "u" ? !1 : t instanceof ShadowRoot || t instanceof H(t).ShadowRoot;
}
function it(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: o,
    display: i
  } = W(t);
  return /auto|scroll|overlay|hidden|clip/.test(e + o + n) && !["inline", "contents"].includes(i);
}
function Ye(t) {
  return ["table", "td", "th"].includes(tt(t));
}
function bt(t) {
  const e = At(), n = W(t);
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !e && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !e && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((o) => (n.willChange || "").includes(o)) || ["paint", "layout", "strict", "content"].some((o) => (n.contain || "").includes(o));
}
function je(t) {
  let e = q(t);
  for (; z(e) && !G(e); ) {
    if (bt(e))
      return e;
    e = q(e);
  }
  return null;
}
function At() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function G(t) {
  return ["html", "body", "#document"].includes(tt(t));
}
function W(t) {
  return H(t).getComputedStyle(t);
}
function mt(t) {
  return V(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.pageXOffset,
    scrollTop: t.pageYOffset
  };
}
function q(t) {
  if (tt(t) === "html")
    return t;
  const e = (
    // Step into the shadow DOM of the parent of a slotted node.
    t.assignedSlot || // DOM Element detected.
    t.parentNode || // ShadowRoot detected.
    Wt(t) && t.host || // Fallback.
    X(t)
  );
  return Wt(e) ? e.host : e;
}
function qt(t) {
  const e = q(t);
  return G(e) ? t.ownerDocument ? t.ownerDocument.body : t.body : z(e) && it(e) ? e : qt(e);
}
function ot(t, e, n) {
  var o;
  e === void 0 && (e = []), n === void 0 && (n = !0);
  const i = qt(t), r = i === ((o = t.ownerDocument) == null ? void 0 : o.body), s = H(i);
  return r ? e.concat(s, s.visualViewport || [], it(i) ? i : [], s.frameElement && n ? ot(s.frameElement) : []) : e.concat(i, ot(i, [], n));
}
function Ut(t) {
  const e = W(t);
  let n = parseFloat(e.width) || 0, o = parseFloat(e.height) || 0;
  const i = z(t), r = i ? t.offsetWidth : n, s = i ? t.offsetHeight : o, l = at(n) !== r || at(o) !== s;
  return l && (n = r, o = s), {
    width: n,
    height: o,
    $: l
  };
}
function Rt(t) {
  return V(t) ? t : t.contextElement;
}
function Z(t) {
  const e = Rt(t);
  if (!z(e))
    return K(1);
  const n = e.getBoundingClientRect(), {
    width: o,
    height: i,
    $: r
  } = Ut(e);
  let s = (r ? at(n.width) : n.width) / o, l = (r ? at(n.height) : n.height) / i;
  return (!s || !Number.isFinite(s)) && (s = 1), (!l || !Number.isFinite(l)) && (l = 1), {
    x: s,
    y: l
  };
}
const Xe = /* @__PURE__ */ K(0);
function Zt(t) {
  const e = H(t);
  return !At() || !e.visualViewport ? Xe : {
    x: e.visualViewport.offsetLeft,
    y: e.visualViewport.offsetTop
  };
}
function Ke(t, e, n) {
  return e === void 0 && (e = !1), !n || e && n !== H(t) ? !1 : e;
}
function U(t, e, n, o) {
  e === void 0 && (e = !1), n === void 0 && (n = !1);
  const i = t.getBoundingClientRect(), r = Rt(t);
  let s = K(1);
  e && (o ? V(o) && (s = Z(o)) : s = Z(t));
  const l = Ke(r, n, o) ? Zt(r) : K(0);
  let c = (i.left + l.x) / s.x, a = (i.top + l.y) / s.y, d = i.width / s.x, u = i.height / s.y;
  if (r) {
    const m = H(r), f = o && V(o) ? H(o) : o;
    let p = m, h = p.frameElement;
    for (; h && o && f !== p; ) {
      const w = Z(h), g = h.getBoundingClientRect(), x = W(h), b = g.left + (h.clientLeft + parseFloat(x.paddingLeft)) * w.x, y = g.top + (h.clientTop + parseFloat(x.paddingTop)) * w.y;
      c *= w.x, a *= w.y, d *= w.x, u *= w.y, c += b, a += y, p = H(h), h = p.frameElement;
    }
  }
  return ut({
    width: d,
    height: u,
    x: c,
    y: a
  });
}
const qe = [":popover-open", ":modal"];
function Ct(t) {
  return qe.some((e) => {
    try {
      return t.matches(e);
    } catch {
      return !1;
    }
  });
}
function Ue(t) {
  let {
    elements: e,
    rect: n,
    offsetParent: o,
    strategy: i
  } = t;
  const r = i === "fixed", s = X(o), l = e ? Ct(e.floating) : !1;
  if (o === s || l && r)
    return n;
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  }, a = K(1);
  const d = K(0), u = z(o);
  if ((u || !u && !r) && ((tt(o) !== "body" || it(s)) && (c = mt(o)), z(o))) {
    const m = U(o);
    a = Z(o), d.x = m.x + o.clientLeft, d.y = m.y + o.clientTop;
  }
  return {
    width: n.width * a.x,
    height: n.height * a.y,
    x: n.x * a.x - c.scrollLeft * a.x + d.x,
    y: n.y * a.y - c.scrollTop * a.y + d.y
  };
}
function Ze(t) {
  return Array.from(t.getClientRects());
}
function Gt(t) {
  return U(X(t)).left + mt(t).scrollLeft;
}
function Ge(t) {
  const e = X(t), n = mt(t), o = t.ownerDocument.body, i = N(e.scrollWidth, e.clientWidth, o.scrollWidth, o.clientWidth), r = N(e.scrollHeight, e.clientHeight, o.scrollHeight, o.clientHeight);
  let s = -n.scrollLeft + Gt(t);
  const l = -n.scrollTop;
  return W(o).direction === "rtl" && (s += N(e.clientWidth, o.clientWidth) - i), {
    width: i,
    height: r,
    x: s,
    y: l
  };
}
function Je(t, e) {
  const n = H(t), o = X(t), i = n.visualViewport;
  let r = o.clientWidth, s = o.clientHeight, l = 0, c = 0;
  if (i) {
    r = i.width, s = i.height;
    const a = At();
    (!a || a && e === "fixed") && (l = i.offsetLeft, c = i.offsetTop);
  }
  return {
    width: r,
    height: s,
    x: l,
    y: c
  };
}
function Qe(t, e) {
  const n = U(t, !0, e === "fixed"), o = n.top + t.clientTop, i = n.left + t.clientLeft, r = z(t) ? Z(t) : K(1), s = t.clientWidth * r.x, l = t.clientHeight * r.y, c = i * r.x, a = o * r.y;
  return {
    width: s,
    height: l,
    x: c,
    y: a
  };
}
function _t(t, e, n) {
  let o;
  if (e === "viewport")
    o = Je(t, n);
  else if (e === "document")
    o = Ge(X(t));
  else if (V(e))
    o = Qe(e, n);
  else {
    const i = Zt(t);
    o = {
      ...e,
      x: e.x - i.x,
      y: e.y - i.y
    };
  }
  return ut(o);
}
function Jt(t, e) {
  const n = q(t);
  return n === e || !V(n) || G(n) ? !1 : W(n).position === "fixed" || Jt(n, e);
}
function tn(t, e) {
  const n = e.get(t);
  if (n)
    return n;
  let o = ot(t, [], !1).filter((l) => V(l) && tt(l) !== "body"), i = null;
  const r = W(t).position === "fixed";
  let s = r ? q(t) : t;
  for (; V(s) && !G(s); ) {
    const l = W(s), c = bt(s);
    !c && l.position === "fixed" && (i = null), (r ? !c && !i : !c && l.position === "static" && !!i && ["absolute", "fixed"].includes(i.position) || it(s) && !c && Jt(t, s)) ? o = o.filter((d) => d !== s) : i = l, s = q(s);
  }
  return e.set(t, o), o;
}
function en(t) {
  let {
    element: e,
    boundary: n,
    rootBoundary: o,
    strategy: i
  } = t;
  const s = [...n === "clippingAncestors" ? Ct(e) ? [] : tn(e, this._c) : [].concat(n), o], l = s[0], c = s.reduce((a, d) => {
    const u = _t(e, d, i);
    return a.top = N(u.top, a.top), a.right = _(u.right, a.right), a.bottom = _(u.bottom, a.bottom), a.left = N(u.left, a.left), a;
  }, _t(e, l, i));
  return {
    width: c.right - c.left,
    height: c.bottom - c.top,
    x: c.left,
    y: c.top
  };
}
function nn(t) {
  const {
    width: e,
    height: n
  } = Ut(t);
  return {
    width: e,
    height: n
  };
}
function on(t, e, n) {
  const o = z(e), i = X(e), r = n === "fixed", s = U(t, !0, r, e);
  let l = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const c = K(0);
  if (o || !o && !r)
    if ((tt(e) !== "body" || it(i)) && (l = mt(e)), o) {
      const u = U(e, !0, r, e);
      c.x = u.x + e.clientLeft, c.y = u.y + e.clientTop;
    } else i && (c.x = Gt(i));
  const a = s.left + l.scrollLeft - c.x, d = s.top + l.scrollTop - c.y;
  return {
    x: a,
    y: d,
    width: s.width,
    height: s.height
  };
}
function ht(t) {
  return W(t).position === "static";
}
function Bt(t, e) {
  return !z(t) || W(t).position === "fixed" ? null : e ? e(t) : t.offsetParent;
}
function Qt(t, e) {
  const n = H(t);
  if (Ct(t))
    return n;
  if (!z(t)) {
    let i = q(t);
    for (; i && !G(i); ) {
      if (V(i) && !ht(i))
        return i;
      i = q(i);
    }
    return n;
  }
  let o = Bt(t, e);
  for (; o && Ye(o) && ht(o); )
    o = Bt(o, e);
  return o && G(o) && ht(o) && !bt(o) ? n : o || je(t) || n;
}
const rn = async function(t) {
  const e = this.getOffsetParent || Qt, n = this.getDimensions, o = await n(t.floating);
  return {
    reference: on(t.reference, await e(t.floating), t.strategy),
    floating: {
      x: 0,
      y: 0,
      width: o.width,
      height: o.height
    }
  };
};
function sn(t) {
  return W(t).direction === "rtl";
}
const cn = {
  convertOffsetParentRelativeRectToViewportRelativeRect: Ue,
  getDocumentElement: X,
  getClippingRect: en,
  getOffsetParent: Qt,
  getElementRects: rn,
  getClientRects: Ze,
  getDimensions: nn,
  getScale: Z,
  isElement: V,
  isRTL: sn
};
function ln(t, e) {
  let n = null, o;
  const i = X(t);
  function r() {
    var l;
    clearTimeout(o), (l = n) == null || l.disconnect(), n = null;
  }
  function s(l, c) {
    l === void 0 && (l = !1), c === void 0 && (c = 1), r();
    const {
      left: a,
      top: d,
      width: u,
      height: m
    } = t.getBoundingClientRect();
    if (l || e(), !u || !m)
      return;
    const f = ct(d), p = ct(i.clientWidth - (a + u)), h = ct(i.clientHeight - (d + m)), w = ct(a), x = {
      rootMargin: -f + "px " + -p + "px " + -h + "px " + -w + "px",
      threshold: N(0, _(1, c)) || 1
    };
    let b = !0;
    function y(v) {
      const A = v[0].intersectionRatio;
      if (A !== c) {
        if (!b)
          return s();
        A ? s(!1, A) : o = setTimeout(() => {
          s(!1, 1e-7);
        }, 1e3);
      }
      b = !1;
    }
    try {
      n = new IntersectionObserver(y, {
        ...x,
        // Handle <iframe>s
        root: i.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(y, x);
    }
    n.observe(t);
  }
  return s(!0), r;
}
function an(t, e, n, o) {
  o === void 0 && (o = {});
  const {
    ancestorScroll: i = !0,
    ancestorResize: r = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: l = typeof IntersectionObserver == "function",
    animationFrame: c = !1
  } = o, a = Rt(t), d = i || r ? [...a ? ot(a) : [], ...ot(e)] : [];
  d.forEach((g) => {
    i && g.addEventListener("scroll", n, {
      passive: !0
    }), r && g.addEventListener("resize", n);
  });
  const u = a && l ? ln(a, n) : null;
  let m = -1, f = null;
  s && (f = new ResizeObserver((g) => {
    let [x] = g;
    x && x.target === a && f && (f.unobserve(e), cancelAnimationFrame(m), m = requestAnimationFrame(() => {
      var b;
      (b = f) == null || b.observe(e);
    })), n();
  }), a && !c && f.observe(a), f.observe(e));
  let p, h = c ? U(t) : null;
  c && w();
  function w() {
    const g = U(t);
    h && (g.x !== h.x || g.y !== h.y || g.width !== h.width || g.height !== h.height) && n(), h = g, p = requestAnimationFrame(w);
  }
  return n(), () => {
    var g;
    d.forEach((x) => {
      i && x.removeEventListener("scroll", n), r && x.removeEventListener("resize", n);
    }), u == null || u(), (g = f) == null || g.disconnect(), f = null, c && cancelAnimationFrame(p);
  };
}
const fn = Be, un = Ve, dn = Fe, pn = Ie, mn = We, Vt = He, hn = ze, gn = (t, e, n) => {
  const o = /* @__PURE__ */ new Map(), i = {
    platform: cn,
    ...n
  }, r = {
    ...i.platform,
    _c: o
  };
  return Ne(t, e, {
    ...i,
    platform: r
  });
};
var lt = typeof document < "u" ? Ae : Re;
function dt(t, e) {
  if (t === e)
    return !0;
  if (typeof t != typeof e)
    return !1;
  if (typeof t == "function" && t.toString() === e.toString())
    return !0;
  let n, o, i;
  if (t && e && typeof t == "object") {
    if (Array.isArray(t)) {
      if (n = t.length, n !== e.length) return !1;
      for (o = n; o-- !== 0; )
        if (!dt(t[o], e[o]))
          return !1;
      return !0;
    }
    if (i = Object.keys(t), n = i.length, n !== Object.keys(e).length)
      return !1;
    for (o = n; o-- !== 0; )
      if (!{}.hasOwnProperty.call(e, i[o]))
        return !1;
    for (o = n; o-- !== 0; ) {
      const r = i[o];
      if (!(r === "_owner" && t.$$typeof) && !dt(t[r], e[r]))
        return !1;
    }
    return !0;
  }
  return t !== t && e !== e;
}
function te(t) {
  return typeof window > "u" ? 1 : (t.ownerDocument.defaultView || window).devicePixelRatio || 1;
}
function zt(t, e) {
  const n = te(t);
  return Math.round(e * n) / n;
}
function It(t) {
  const e = C.useRef(t);
  return lt(() => {
    e.current = t;
  }), e;
}
function wn(t) {
  t === void 0 && (t = {});
  const {
    placement: e = "bottom",
    strategy: n = "absolute",
    middleware: o = [],
    platform: i,
    elements: {
      reference: r,
      floating: s
    } = {},
    transform: l = !0,
    whileElementsMounted: c,
    open: a
  } = t, [d, u] = C.useState({
    x: 0,
    y: 0,
    strategy: n,
    placement: e,
    middlewareData: {},
    isPositioned: !1
  }), [m, f] = C.useState(o);
  dt(m, o) || f(o);
  const [p, h] = C.useState(null), [w, g] = C.useState(null), x = C.useCallback((R) => {
    R !== A.current && (A.current = R, h(R));
  }, []), b = C.useCallback((R) => {
    R !== P.current && (P.current = R, g(R));
  }, []), y = r || p, v = s || w, A = C.useRef(null), P = C.useRef(null), S = C.useRef(d), F = c != null, D = It(c), T = It(i), E = C.useCallback(() => {
    if (!A.current || !P.current)
      return;
    const R = {
      placement: e,
      strategy: n,
      middleware: m
    };
    T.current && (R.platform = T.current), gn(A.current, P.current, R).then(($) => {
      const I = {
        ...$,
        isPositioned: !0
      };
      k.current && !dt(S.current, I) && (S.current = I, Pe.flushSync(() => {
        u(I);
      }));
    });
  }, [m, e, n, T]);
  lt(() => {
    a === !1 && S.current.isPositioned && (S.current.isPositioned = !1, u((R) => ({
      ...R,
      isPositioned: !1
    })));
  }, [a]);
  const k = C.useRef(!1);
  lt(() => (k.current = !0, () => {
    k.current = !1;
  }), []), lt(() => {
    if (y && (A.current = y), v && (P.current = v), y && v) {
      if (D.current)
        return D.current(y, v, E);
      E();
    }
  }, [y, v, E, D, F]);
  const L = C.useMemo(() => ({
    reference: A,
    floating: P,
    setReference: x,
    setFloating: b
  }), [x, b]), O = C.useMemo(() => ({
    reference: y,
    floating: v
  }), [y, v]), M = C.useMemo(() => {
    const R = {
      position: n,
      left: 0,
      top: 0
    };
    if (!O.floating)
      return R;
    const $ = zt(O.floating, d.x), I = zt(O.floating, d.y);
    return l ? {
      ...R,
      transform: "translate(" + $ + "px, " + I + "px)",
      ...te(O.floating) >= 1.5 && {
        willChange: "transform"
      }
    } : {
      position: n,
      left: $,
      top: I
    };
  }, [n, l, O.floating, d.x, d.y]);
  return C.useMemo(() => ({
    ...d,
    update: E,
    refs: L,
    elements: O,
    floatingStyles: M
  }), [d, E, L, O, M]);
}
const xn = (t) => {
  function e(n) {
    return {}.hasOwnProperty.call(n, "current");
  }
  return {
    name: "arrow",
    options: t,
    fn(n) {
      const {
        element: o,
        padding: i
      } = typeof t == "function" ? t(n) : t;
      return o && e(o) ? o.current != null ? Vt({
        element: o.current,
        padding: i
      }).fn(n) : {} : o ? Vt({
        element: o,
        padding: i
      }).fn(n) : {};
    }
  };
}, yn = (t, e) => ({
  ...fn(t),
  options: [t, e]
}), vn = (t, e) => ({
  ...un(t),
  options: [t, e]
}), bn = (t, e) => ({
  ...hn(t),
  options: [t, e]
}), An = (t, e) => ({
  ...dn(t),
  options: [t, e]
}), Rn = (t, e) => ({
  ...pn(t),
  options: [t, e]
}), Cn = (t, e) => ({
  ...mn(t),
  options: [t, e]
}), Pn = (t, e) => ({
  ...xn(t),
  options: [t, e]
});
var On = "Arrow", ee = C.forwardRef((t, e) => {
  const { children: n, width: o = 10, height: i = 5, ...r } = t;
  return /* @__PURE__ */ B(
    pt.svg,
    {
      ...r,
      ref: e,
      width: o,
      height: i,
      viewBox: "0 0 30 10",
      preserveAspectRatio: "none",
      children: t.asChild ? n : /* @__PURE__ */ B("polygon", { points: "0,0 30,0 15,10" })
    }
  );
});
ee.displayName = On;
var En = ee, Pt = "Popper", [ne, zn] = Ce(Pt), [Sn, oe] = ne(Pt), ie = (t) => {
  const { __scopePopper: e, children: n } = t, [o, i] = C.useState(null);
  return /* @__PURE__ */ B(Sn, { scope: e, anchor: o, onAnchorChange: i, children: n });
};
ie.displayName = Pt;
var re = "PopperAnchor", se = C.forwardRef(
  (t, e) => {
    const { __scopePopper: n, virtualRef: o, ...i } = t, r = oe(re, n), s = C.useRef(null), l = jt(e, s);
    return C.useEffect(() => {
      r.onAnchorChange((o == null ? void 0 : o.current) || s.current);
    }), o ? null : /* @__PURE__ */ B(pt.div, { ...i, ref: l });
  }
);
se.displayName = re;
var Ot = "PopperContent", [Ln, Tn] = ne(Ot), ce = C.forwardRef(
  (t, e) => {
    var Et, St, Lt, Tt, Mt, Dt;
    const {
      __scopePopper: n,
      side: o = "bottom",
      sideOffset: i = 0,
      align: r = "center",
      alignOffset: s = 0,
      arrowPadding: l = 0,
      avoidCollisions: c = !0,
      collisionBoundary: a = [],
      collisionPadding: d = 0,
      sticky: u = "partial",
      hideWhenDetached: m = !1,
      updatePositionStrategy: f = "optimized",
      onPlaced: p,
      ...h
    } = t, w = oe(Ot, n), [g, x] = C.useState(null), b = jt(e, (et) => x(et)), [y, v] = C.useState(null), A = Oe(y), P = (A == null ? void 0 : A.width) ?? 0, S = (A == null ? void 0 : A.height) ?? 0, F = o + (r !== "center" ? "-" + r : ""), D = typeof d == "number" ? d : { top: 0, right: 0, bottom: 0, left: 0, ...d }, T = Array.isArray(a) ? a : [a], E = T.length > 0, k = {
      padding: D,
      boundary: T.filter(Dn),
      // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
      altBoundary: E
    }, { refs: L, floatingStyles: O, placement: M, isPositioned: R, middlewareData: $ } = wn({
      // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
      strategy: "fixed",
      placement: F,
      whileElementsMounted: (...et) => an(...et, {
        animationFrame: f === "always"
      }),
      elements: {
        reference: w.anchor
      },
      middleware: [
        yn({ mainAxis: i + S, alignmentAxis: s }),
        c && vn({
          mainAxis: !0,
          crossAxis: !1,
          limiter: u === "partial" ? bn() : void 0,
          ...k
        }),
        c && An({ ...k }),
        Rn({
          ...k,
          apply: ({ elements: et, rects: kt, availableWidth: xe, availableHeight: ye }) => {
            const { width: ve, height: be } = kt.reference, st = et.floating.style;
            st.setProperty("--radix-popper-available-width", `${xe}px`), st.setProperty("--radix-popper-available-height", `${ye}px`), st.setProperty("--radix-popper-anchor-width", `${ve}px`), st.setProperty("--radix-popper-anchor-height", `${be}px`);
          }
        }),
        y && Pn({ element: y, padding: l }),
        kn({ arrowWidth: P, arrowHeight: S }),
        m && Cn({ strategy: "referenceHidden", ...k })
      ]
    }), [I, de] = fe(M), rt = Yt(p);
    $t(() => {
      R && (rt == null || rt());
    }, [R, rt]);
    const pe = (Et = $.arrow) == null ? void 0 : Et.x, me = (St = $.arrow) == null ? void 0 : St.y, he = ((Lt = $.arrow) == null ? void 0 : Lt.centerOffset) !== 0, [ge, we] = C.useState();
    return $t(() => {
      g && we(window.getComputedStyle(g).zIndex);
    }, [g]), /* @__PURE__ */ B(
      "div",
      {
        ref: L.setFloating,
        "data-radix-popper-content-wrapper": "",
        style: {
          ...O,
          transform: R ? O.transform : "translate(0, -200%)",
          // keep off the page when measuring
          minWidth: "max-content",
          zIndex: ge,
          "--radix-popper-transform-origin": [
            (Tt = $.transformOrigin) == null ? void 0 : Tt.x,
            (Mt = $.transformOrigin) == null ? void 0 : Mt.y
          ].join(" "),
          // hide the content if using the hide middleware and should be hidden
          // set visibility to hidden and disable pointer events so the UI behaves
          // as if the PopperContent isn't there at all
          ...((Dt = $.hide) == null ? void 0 : Dt.referenceHidden) && {
            visibility: "hidden",
            pointerEvents: "none"
          }
        },
        dir: t.dir,
        children: /* @__PURE__ */ B(
          Ln,
          {
            scope: n,
            placedSide: I,
            onArrowChange: v,
            arrowX: pe,
            arrowY: me,
            shouldHideArrow: he,
            children: /* @__PURE__ */ B(
              pt.div,
              {
                "data-side": I,
                "data-align": de,
                ...h,
                ref: b,
                style: {
                  ...h.style,
                  // if the PopperContent hasn't been placed yet (not all measurements done)
                  // we prevent animations so that users's animation don't kick in too early referring wrong sides
                  animation: R ? void 0 : "none"
                }
              }
            )
          }
        )
      }
    );
  }
);
ce.displayName = Ot;
var le = "PopperArrow", Mn = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right"
}, ae = C.forwardRef(function(e, n) {
  const { __scopePopper: o, ...i } = e, r = Tn(le, o), s = Mn[r.placedSide];
  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    /* @__PURE__ */ B(
      "span",
      {
        ref: r.onArrowChange,
        style: {
          position: "absolute",
          left: r.arrowX,
          top: r.arrowY,
          [s]: 0,
          transformOrigin: {
            top: "",
            right: "0 0",
            bottom: "center 0",
            left: "100% 0"
          }[r.placedSide],
          transform: {
            top: "translateY(100%)",
            right: "translateY(50%) rotate(90deg) translateX(-50%)",
            bottom: "rotate(180deg)",
            left: "translateY(50%) rotate(-90deg) translateX(50%)"
          }[r.placedSide],
          visibility: r.shouldHideArrow ? "hidden" : void 0
        },
        children: /* @__PURE__ */ B(
          En,
          {
            ...i,
            ref: n,
            style: {
              ...i.style,
              // ensures the element can be measured correctly (mostly for if SVG)
              display: "block"
            }
          }
        )
      }
    )
  );
});
ae.displayName = le;
function Dn(t) {
  return t !== null;
}
var kn = (t) => ({
  name: "transformOrigin",
  options: t,
  fn(e) {
    var w, g, x;
    const { placement: n, rects: o, middlewareData: i } = e, s = ((w = i.arrow) == null ? void 0 : w.centerOffset) !== 0, l = s ? 0 : t.arrowWidth, c = s ? 0 : t.arrowHeight, [a, d] = fe(n), u = { start: "0%", center: "50%", end: "100%" }[d], m = (((g = i.arrow) == null ? void 0 : g.x) ?? 0) + l / 2, f = (((x = i.arrow) == null ? void 0 : x.y) ?? 0) + c / 2;
    let p = "", h = "";
    return a === "bottom" ? (p = s ? u : `${m}px`, h = `${-c}px`) : a === "top" ? (p = s ? u : `${m}px`, h = `${o.floating.height + c}px`) : a === "right" ? (p = `${-c}px`, h = s ? u : `${f}px`) : a === "left" && (p = `${o.floating.width + c}px`, h = s ? u : `${f}px`), { data: { x: p, y: h } };
  }
});
function fe(t) {
  const [e, n = "center"] = t.split("-");
  return [e, n];
}
var In = ie, Yn = se, jn = ce, Xn = ae, $n = "VisuallyHidden", ue = C.forwardRef(
  (t, e) => /* @__PURE__ */ B(
    pt.span,
    {
      ...t,
      ref: e,
      style: {
        // See: https://github.com/twbs/bootstrap/blob/master/scss/mixins/_screen-reader.scss
        position: "absolute",
        border: 0,
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        wordWrap: "normal",
        ...t.style
      }
    }
  )
);
ue.displayName = $n;
var Kn = ue;
export {
  Yn as A,
  jn as C,
  Kn as R,
  ue as V,
  Xn as a,
  In as b,
  zn as c,
  Vn as u
};
