import * as n from "react";
import { u as r } from "./index-CVQolMUX.js";
function R(t, c, { checkForDefaultPrevented: o = !0 } = {}) {
  return function(e) {
    if (t == null || t(e), o === !1 || !e.defaultPrevented)
      return c == null ? void 0 : c(e);
  };
}
function S({
  prop: t,
  defaultProp: c,
  onChange: o = () => {
  }
}) {
  const [s, e] = C({ defaultProp: c, onChange: o }), u = t !== void 0, i = u ? t : s, l = r(o), m = n.useCallback(
    (f) => {
      if (u) {
        const a = typeof f == "function" ? f(t) : f;
        a !== t && l(a);
      } else
        e(f);
    },
    [u, t, e, l]
  );
  return [i, m];
}
function C({
  defaultProp: t,
  onChange: c
}) {
  const o = n.useState(t), [s] = o, e = n.useRef(s), u = r(c);
  return n.useEffect(() => {
    e.current !== s && (u(s), e.current = s);
  }, [s, e, u]), o;
}
export {
  R as c,
  S as u
};
