import { jsx as c, jsxs as b } from "react/jsx-runtime";
import * as l from "react";
import { forwardRef as w } from "react";
import { c as N, u as C, a as f } from "../../index-CVQolMUX.js";
import { P as u } from "../../index-u2zzFwMH.js";
import { c as I } from "../../index-BwYCDOHZ.js";
import { cn as R } from "../../utils/cn.js";
var v = "Avatar", [h, $] = N(v), [_, g] = h(v), p = l.forwardRef(
  (e, s) => {
    const { __scopeAvatar: t, ...a } = e, [r, o] = l.useState("idle");
    return /* @__PURE__ */ c(
      _,
      {
        scope: t,
        imageLoadingStatus: r,
        onImageLoadingStatusChange: o,
        children: /* @__PURE__ */ c(u.span, { ...a, ref: s })
      }
    );
  }
);
p.displayName = v;
var x = "AvatarImage", A = l.forwardRef(
  (e, s) => {
    const { __scopeAvatar: t, src: a, onLoadingStatusChange: r = () => {
    }, ...o } = e, i = g(x, t), n = z(a), d = C((m) => {
      r(m), i.onImageLoadingStatusChange(m);
    });
    return f(() => {
      n !== "idle" && d(n);
    }, [n, d]), n === "loaded" ? /* @__PURE__ */ c(u.img, { ...o, ref: s, src: a }) : null;
  }
);
A.displayName = x;
var S = "AvatarFallback", y = l.forwardRef(
  (e, s) => {
    const { __scopeAvatar: t, delayMs: a, ...r } = e, o = g(S, t), [i, n] = l.useState(a === void 0);
    return l.useEffect(() => {
      if (a !== void 0) {
        const d = window.setTimeout(() => n(!0), a);
        return () => window.clearTimeout(d);
      }
    }, [a]), i && o.imageLoadingStatus !== "loaded" ? /* @__PURE__ */ c(u.span, { ...r, ref: s }) : null;
  }
);
y.displayName = S;
function z(e) {
  const [s, t] = l.useState("idle");
  return f(() => {
    if (!e) {
      t("error");
      return;
    }
    let a = !0;
    const r = new window.Image(), o = (i) => () => {
      a && t(i);
    };
    return t("loading"), r.onload = o("loaded"), r.onerror = o("error"), r.src = e, () => {
      a = !1;
    };
  }, [e]), s;
}
var k = p, E = A, M = y;
const P = I(
  "inline-flex items-center justify-center overflow-hidden rounded-full align-middle",
  {
    variants: {
      variant: {
        filled: "text-text-primary-inverted",
        stroke: "border border-el-stroke-primary text-text-primary"
      },
      color: {
        primary: "",
        revealed: ""
      },
      size: {
        sm: "size-s-4 text-[12px]",
        md: "size-s-5 text-[18px]",
        lg: "size-s-6 text-[16px]",
        xl: "size-s-8 text-[22px]",
        "2xl": "size-s-9 text-[28px]",
        "3xl": "size-s-10 text-[40px]"
      }
    },
    compoundVariants: [
      {
        variant: "filled",
        color: "primary",
        className: "bg-el-bg-avatar-primary"
      },
      {
        variant: "filled",
        color: "revealed",
        className: "bg-el-bg-avatar-revealed"
      }
    ]
  }
), j = w(
  ({
    className: e,
    src: s,
    alt: t,
    onLoadingStatusChange: a,
    fallback: r,
    delayMs: o,
    size: i = "lg",
    variant: n = "filled",
    color: d = "primary",
    ...m
  }, L) => /* @__PURE__ */ b(
    k,
    {
      ref: L,
      className: R(P({ size: i, variant: n, color: d, className: e })),
      ...m,
      children: [
        /* @__PURE__ */ c(
          E,
          {
            className: "size-full object-cover",
            src: s,
            alt: t,
            onLoadingStatusChange: a
          }
        ),
        /* @__PURE__ */ c(
          M,
          {
            className: "mt-[7%] flex select-none items-center justify-center font-serif font-medium leading-none",
            delayMs: o,
            children: r
          }
        )
      ]
    }
  )
);
j.displayName = "Avatar";
export {
  j as Avatar
};
