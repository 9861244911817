import * as Sentry from "@sentry/nextjs";
import { add, differenceInHours, endOfDay } from "date-fns";

import { analytics } from "@flare/analytics";

import { getCompetencies } from "@/components/Funnel/utils/get-competencies";
import { getFips } from "@/components/Funnel/utils/get-fips";
import type {
  BookitCreateRequestResponse,
  GetAvailableSlotsRequest,
  PracticeArea,
} from "@/edge/contract";
import { objectToQuery } from "@/edge/contract";
import {
  makeGetAvailableSlotsRequest,
  normalizePracticeAreaName,
} from "@/edge/contract";
import { jsonRequest } from "@/lib/network/json-request";
import { safeAsync } from "@/lib/safe";
import type { FunnelAnswers } from "@/modules/v2/funnel/config";
import { BiEventsNames } from "@/services/analytics/event-names";
import { requestTimeout } from "@/utils/request-timeout";

import {
  isQualifiedInternalEmail,
  testCounty,
  testStateCode,
} from "./checkQualifiedInternalEmail";

const MAX_MS_BOOKIT_RESPONSE = 15000;
const MAX_HOURS_TO_INTAKE_MEETING = 2;
const MAX_DAYS_TO_LSS_MEETING = 2;
export const MIN_LSS_SLOTS = 2;

type ApplyLssQualificationArgs = {
  additionalEventData?: Record<string, string | undefined>;
  funnelAnswers: FunnelAnswers;
  isQualified: boolean;
  outcomeReason: string;
};

function applyLssQualification({
  isQualified,
  outcomeReason,
  funnelAnswers,
  additionalEventData,
}: ApplyLssQualificationArgs) {
  funnelAnswers.isQualifiedForLss = isQualified;

  analytics.track(BiEventsNames.WebDirectLssQualification, {
    is_qualified: isQualified,
    outcome_reason: outcomeReason,
    funnel_answers: funnelAnswers,

    ...additionalEventData,
  });
}

export async function calculateLssQualification(funnelAnswers: FunnelAnswers) {
  const {
    practice,
    subPractice,
    county,
    eventStartTime,
    stateCode,
    email,
    serviceType,
    sponsorType,
  } = funnelAnswers;

  if (!practice || !subPractice) {
    applyLssQualification({
      isQualified: false,
      outcomeReason: "missing required funnel answers",
      funnelAnswers,
    });
    return false;
  }

  // Checks if county is known (Immigration doesn't require county)
  if (practice === "family" && !county) {
    applyLssQualification({
      isQualified: false,
      outcomeReason: "no county",
      funnelAnswers,
    });
    return false;
  }

  // Checks that the intake meeting is more than MAX_HOURS_TO_INTAKE_MEETING

  const currentDate = new Date();

  if (eventStartTime) {
    if (eventStartTime === "call_me_now") {
      applyLssQualification({
        isQualified: false,
        outcomeReason: "intake meeting close",
        funnelAnswers,
      });
      return false;
    }

    const intakeMeetingTime = new Date(eventStartTime);
    const hoursDifference = Math.abs(
      differenceInHours(intakeMeetingTime, currentDate),
    );
    if (hoursDifference < MAX_HOURS_TO_INTAKE_MEETING) {
      applyLssQualification({
        isQualified: false,
        outcomeReason: "intake meeting close",
        funnelAnswers,
      });
      return false;
    }
  }

  const practiceArea: PracticeArea = normalizePracticeAreaName(practice);
  const competencies = getCompetencies({
    practice: practiceArea,
    subPractice,
    serviceType,
    sponsorType,
  });

  const [actualStateCode, actualCounty] = isQualifiedInternalEmail(
    email,
    "dlss",
  )
    ? [testStateCode, testCounty]
    : [stateCode, county];

  const fips = getFips({
    county: actualCounty,
    stateCode: actualStateCode,
  });

  const now = new Date();

  const availableSlotsPayload: GetAvailableSlotsRequest =
    makeGetAvailableSlotsRequest({
      practiceArea,
      competencies,
      minSlots: MIN_LSS_SLOTS,
      from: now,
      to: endOfDay(add(now, { days: MAX_DAYS_TO_LSS_MEETING })),
    });

  if (practiceArea === "Family") {
    availableSlotsPayload.stateCode = actualStateCode;
    availableSlotsPayload.fips = fips;
  }

  const [error, result] = await safeAsync(() =>
    Promise.race([
      jsonRequest<BookitCreateRequestResponse>(
        `/api/vinny/bookee/availability/LSS?${objectToQuery(makeGetAvailableSlotsRequest(availableSlotsPayload))}`,
        {
          method: "GET",
        },
      ),
      requestTimeout(MAX_MS_BOOKIT_RESPONSE),
    ]),
  );

  if (error) {
    Sentry.captureException(error, {
      extra: {
        message: "error fetching lss slots for calculateLssQualification",
        practiceArea: practice.toString().toUpperCase(),
        stateCode,
        action: "fetch_lss_slots",
      },
    });
    analytics.track(BiEventsNames.WebFunnelError, {
      error_type: "is_qualified_for_lss_get_available_slots_error",
      error_message: error?.message,
    });
    applyLssQualification({
      isQualified: false,
      outcomeReason: "fetch lss slots failure",
      funnelAnswers,
      additionalEventData: {
        practice_area: practiceArea,
        state_code: stateCode,
        fips,
        competencies: competencies.toString(),
      },
    });
    return false;
  } else if (!result) {
    applyLssQualification({
      isQualified: false,
      outcomeReason: "no nearby lss slot",
      funnelAnswers,
      additionalEventData: {
        practice_area: practiceArea,
        state_code: stateCode,
        fips,
        competencies: competencies.toString(),
      },
    });
    return false;
  }
  applyLssQualification({
    isQualified: true,
    outcomeReason: "success",
    funnelAnswers,
  });
  return true;
}
