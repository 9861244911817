const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
const ONE_MINUTE_IN_MS = 60 * 1000;

export type FunnelParameters =
  | {
      approvedTerms?: boolean;
      campaignId?: string;
      caseUrgency?: string;
      county?: string;
      email: string;
      eventStartTime?: string;
      firstName: string;
      isQualifiedForLss?: boolean;
      lastName: string;
      leadIntent?: "high" | "low";
      leadScore?: number;
      meetingType?: "lss" | "intake" | "cmn";
      moreInfo?: string;
      phone: string;
      practice?: string;
      serviceType?: string;
      state?: string;
      stateCode?: string;
      subPractice?: string;
      visitsCount?: number;
      zipCode?: string;
    }
  | Record<string, unknown>;

export type CreateWebLeadInput = {
  AdditionalQuestion__c?: string;
  Concurrence__c?: boolean;
  County__c?: string;
  Current_Situation__c?: string;
  Email__c?: string;
  First_name__c?: string;
  Funnel_Parameters__c?: FunnelParameters;
  Last_name__c?: string;
  Phone__c?: string;
  Postal_code__c?: string;
  Practice__c?: string;
  State__c?: string;
  Test_Group__c?: string;
  Test_Name__c?: string;
  WhatMatterRelated__c?: string;
  affilate_id__c?: string;
  anon_id__c?: string;
  fbclid__c?: string;
  gclid__c?: string;
  match_location__c?: string;
  match_type__c?: string;
  ttclid__c?: string;
  url_refferal__c?: string;
  utm_campaign__c?: string;
  utm_content__c?: string;
  utm_medium__c?: string;
  utm_search_network__c?: string;
  utm_source__c?: string;
  utm_sub_campaign__c?: string;
  utm_term__c?: string;
};

export enum VisitorStatusCode {
  Cnf3 = "CNF3",
  Crh6 = "CRH6",
  Csi2 = "CSI2",
  Cur4 = "CUR4",
  Cws5 = "CWS5",
  Drc7 = "DRC7",
  Elg1 = "ELG1",
  Error = "ERROR",
}

export type VisitorStatusCodeResponse = {
  statusCode: VisitorStatusCode;
};

export type IntakeMeetingSlots = {
  slotTimeSpan: number;
  slots: string[];
};

export type BookeeMeetingType = "ESCALATED_LSS" | "LSS" | "ONE_ON_ONE";
export type PracticeArea = keyof typeof PracticeAreaName;
export enum PracticeAreaName {
  Family = "FAMILY",
  Immigration = "IMMIGRATION",
}
export declare type BookitCreateRequest = {
  appointmentPreferences?: {
    courtAppearance?: boolean;
    criminalHistory?: boolean;
    duration?: number;
    ethnicities?: Array<string>;
    excludedProviders?: Array<string>;
    gender?: "male" | "female" | "other" | "na";
    languages?: Array<string>;
    preferredDateRange?: { from: string; to: string };
    preferredProviders?: Array<string>;
    religions?: Array<string>;
    urgentCases?: boolean;
  };
  competencies?: string[];
  country?: string;
  customerEmail: string;
  customerFirstName?: string;
  customerFlareId?: string;
  customerLastName?: string;
  fips?: string;
  meetingType: BookeeMeetingType;
  metadata?: Record<string, string>;
  practiceArea?: PracticeArea;
  providerId?: string;
  requestId?: string;
  stateCode?: string;
  subscriptions?: {
    secretHeader: string;
    secretValue: string;
    url: string;
  }[];
};

export declare type BookitCreateRequestResponse = {
  id: string;
  timezone: string;
  url: string;
};

export declare type AvailableSlot = {
  end: string;
  start: string;
};

export const normalizePracticeAreaName = (name?: string): PracticeArea =>
  name?.toUpperCase() === "IMMIGRATION" ? "Immigration" : "Family";

export declare type GetAvailableSlotsRequest = {
  competencies?: string[];
  fips?: string;
  from?: Date;
  minSlots?: number;
  practiceArea?: PracticeArea;
  providerFlareId?: string;
  providerId?: string;
  stateCode?: string;
  to?: Date;
};

function dateWithoutSeconds(date: Date) {
  return new Date(date.toISOString().slice(0, 16).replace("T", " "));
}

export const makeGetAvailableSlotsRequest = (
  req: GetAvailableSlotsRequest,
): GetAvailableSlotsRequest => {
  req.from ??= dateWithoutSeconds(new Date());
  // default value is two weeks range minus 10 seconds due to the time diff between 'from' init and 'to' init
  req.to ??= dateWithoutSeconds(
    new Date(Date.now() + ONE_WEEK_IN_MS - ONE_MINUTE_IN_MS),
  );
  req.minSlots ??= 5;
  return req;
};

export const objectToQuery = (obj: object) => {
  const entries = Object.entries(obj)
    .filter(([, value]) => Boolean(value))
    .map(([key, value]) => [
      key,
      value instanceof Array
        ? value.map((a) => a.toString())
        : value.toString(),
    ]);
  return new URLSearchParams(Object.fromEntries(entries)).toString();
};
