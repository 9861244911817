import { jsx as i } from "react/jsx-runtime";
import { forwardRef as n } from "react";
import { cn as d } from "../../utils/cn.js";
const s = n(
  ({ autoSize: r, className: e, hasError: t, ...o }, a) => /* @__PURE__ */ i(
    "textarea",
    {
      className: d(
        // Base
        "min-h-s-9 w-full resize-y appearance-none rounded-sm border px-sp-4 py-sp-2 text-t1 outline-none transition",
        // Border colors
        "border-action-stroke-input-primary-default hover:border-action-stroke-input-primary-hover focus:border-action-stroke-input-primary-active",
        // Disabled
        "disabled:border-action-stroke-input-primary-disabled disabled:text-action-content-primary-disabled",
        // Error
        {
          "border-action-stroke-input-danger-active hover:border-action-stroke-input-danger-active focus:border-action-stroke-input-danger-active": t
        },
        // Auto size
        {
          "[field-sizing:content]": r
        },
        e
      ),
      ref: a,
      ...o
    }
  )
);
s.displayName = "Textarea";
export {
  s as Textarea
};
