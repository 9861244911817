import { captureException } from "@sentry/nextjs";
import { useMutation } from "@tanstack/react-query";

import { analytics } from "@flare/analytics";
import type { Session, User } from "@flare/next-auth";
import { getSession } from "@flare/next-auth/react";

import { jsonRequest } from "@/lib/network/json-request";
import type { UserDTO } from "@/modules/auth/types";
import { BiEventsNames } from "@/services/analytics/event-names";

import type { FunnelAnswers, FunnelMeta } from "../config";

import { useFunnelMeta } from "./useFunnelMeta";
/**
 * `useUpdateLead` is a hook that returns a function to update a lead (`updateLead`) and the mutation result
 *
 * `updateLead` uses `funnelActionId` to update the lead with the most recent funnel data
 */

interface UpdateWebLeadMutationInput {
  funnelAnswers: FunnelAnswers;
  funnelMeta: FunnelMeta;
  user: User;
  userUpdate: Omit<UserDTO, "id" | "roles" | "email" | "name">;
}

export function useUpdateLead() {
  const { getFunnelMeta } = useFunnelMeta();

  const updateWebLeadMutation = useMutation({
    mutationFn: async (input: UpdateWebLeadMutationInput) => {
      return jsonRequest<Session>("/api/user/update", {
        method: "POST",
        body: input.userUpdate,
      });
    },
    onSuccess: (_, { funnelMeta, funnelAnswers, user }) => {
      analytics.track(BiEventsNames.WebLeadUpdate, {
        practice: funnelAnswers?.practice,
        email_hash: funnelMeta?.hashedEmail,
        phone_hash: funnelMeta?.hashedPhone,
        sf_funnel_action_id: user.integrationInfo?.sfLastWFAId,
      });
    },
    onError: (error, { funnelMeta, funnelAnswers }) => {
      analytics.track(BiEventsNames.WebFunnelError, {
        error_type: "update-lead-error",
        error_message: error?.message,
        practice: funnelAnswers?.practice,
        email_hash: funnelMeta?.hashedEmail,
        phone_hash: funnelMeta?.hashedPhone,
      });

      captureException(error, {
        extra: {
          action: "update-lead",
        },
      });
    },
  });

  async function updateLead(answers?: FunnelAnswers) {
    const session = await getSession();

    if (!session || !session.user) {
      analytics.track(BiEventsNames.WebFunnelError, {
        error_type: "update-lead-error",
        error_message: "no session",
      });

      return;
    }

    const { context } = session;
    answers ??= context?.funnelData as FunnelAnswers;

    const funnelMeta = getFunnelMeta();

    const userUpdate: Omit<UserDTO, "id" | "roles" | "email" | "name"> = {
      ...(answers ? { funnelData: answers as Record<string, string> } : {}),
    };

    return updateWebLeadMutation.mutateAsync({
      user: session.user,
      userUpdate,
      funnelAnswers: answers,
      funnelMeta,
    });
  }

  return {
    updateLead,
  };
}
