import { jsx as f, jsxs as z } from "react/jsx-runtime";
import * as s from "react";
import { forwardRef as ae } from "react";
import { c as x, u as ie } from "../../index-BlaUKar8.js";
import { u as _, a as ce } from "../../index-CCavb-1K.js";
import { u as Y, a as le, c as ue } from "../../index-CVQolMUX.js";
import { P as A, d as de } from "../../index-u2zzFwMH.js";
import { u as fe, c as V, A as pe, C as ve, R as he, a as ye, b as me } from "../../index-D8wlo3hw.js";
import { u as Ee } from "../../index-BosAEyXu.js";
import be from "react-dom";
import { P as X } from "../../index-BlE6Vgog.js";
import { cn as Te } from "../../utils/cn.js";
import { focusRing as Ce } from "../../utils/focus-ring.js";
var xe = "DismissableLayer", M = "dismissableLayer.update", ge = "dismissableLayer.pointerDownOutside", Pe = "dismissableLayer.focusOutside", G, $ = s.createContext({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), q = s.forwardRef(
  (t, r) => {
    const {
      disableOutsidePointerEvents: e = !1,
      onEscapeKeyDown: n,
      onPointerDownOutside: o,
      onFocusOutside: a,
      onInteractOutside: c,
      onDismiss: d,
      ...l
    } = t, u = s.useContext($), [i, v] = s.useState(null), h = (i == null ? void 0 : i.ownerDocument) ?? (globalThis == null ? void 0 : globalThis.document), [, y] = s.useState({}), m = _(r, (p) => v(p)), E = Array.from(u.layers), [b] = [...u.layersWithOutsidePointerEventsDisabled].slice(-1), C = E.indexOf(b), T = i ? E.indexOf(i) : -1, P = u.layersWithOutsidePointerEventsDisabled.size > 0, g = T >= C, w = De((p) => {
      const L = p.target, U = [...u.branches].some((I) => I.contains(L));
      !g || U || (o == null || o(p), c == null || c(p), p.defaultPrevented || d == null || d());
    }, h), D = Oe((p) => {
      const L = p.target;
      [...u.branches].some((I) => I.contains(L)) || (a == null || a(p), c == null || c(p), p.defaultPrevented || d == null || d());
    }, h);
    return fe((p) => {
      T === u.layers.size - 1 && (n == null || n(p), !p.defaultPrevented && d && (p.preventDefault(), d()));
    }, h), s.useEffect(() => {
      if (i)
        return e && (u.layersWithOutsidePointerEventsDisabled.size === 0 && (G = h.body.style.pointerEvents, h.body.style.pointerEvents = "none"), u.layersWithOutsidePointerEventsDisabled.add(i)), u.layers.add(i), j(), () => {
          e && u.layersWithOutsidePointerEventsDisabled.size === 1 && (h.body.style.pointerEvents = G);
        };
    }, [i, h, e, u]), s.useEffect(() => () => {
      i && (u.layers.delete(i), u.layersWithOutsidePointerEventsDisabled.delete(i), j());
    }, [i, u]), s.useEffect(() => {
      const p = () => y({});
      return document.addEventListener(M, p), () => document.removeEventListener(M, p);
    }, []), /* @__PURE__ */ f(
      A.div,
      {
        ...l,
        ref: m,
        style: {
          pointerEvents: P ? g ? "auto" : "none" : void 0,
          ...t.style
        },
        onFocusCapture: x(t.onFocusCapture, D.onFocusCapture),
        onBlurCapture: x(t.onBlurCapture, D.onBlurCapture),
        onPointerDownCapture: x(
          t.onPointerDownCapture,
          w.onPointerDownCapture
        )
      }
    );
  }
);
q.displayName = xe;
var we = "DismissableLayerBranch", Re = s.forwardRef((t, r) => {
  const e = s.useContext($), n = s.useRef(null), o = _(r, n);
  return s.useEffect(() => {
    const a = n.current;
    if (a)
      return e.branches.add(a), () => {
        e.branches.delete(a);
      };
  }, [e.branches]), /* @__PURE__ */ f(A.div, { ...t, ref: o });
});
Re.displayName = we;
function De(t, r = globalThis == null ? void 0 : globalThis.document) {
  const e = Y(t), n = s.useRef(!1), o = s.useRef(() => {
  });
  return s.useEffect(() => {
    const a = (d) => {
      if (d.target && !n.current) {
        let l = function() {
          K(
            ge,
            e,
            u,
            { discrete: !0 }
          );
        };
        const u = { originalEvent: d };
        d.pointerType === "touch" ? (r.removeEventListener("click", o.current), o.current = l, r.addEventListener("click", o.current, { once: !0 })) : l();
      } else
        r.removeEventListener("click", o.current);
      n.current = !1;
    }, c = window.setTimeout(() => {
      r.addEventListener("pointerdown", a);
    }, 0);
    return () => {
      window.clearTimeout(c), r.removeEventListener("pointerdown", a), r.removeEventListener("click", o.current);
    };
  }, [r, e]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => n.current = !0
  };
}
function Oe(t, r = globalThis == null ? void 0 : globalThis.document) {
  const e = Y(t), n = s.useRef(!1);
  return s.useEffect(() => {
    const o = (a) => {
      a.target && !n.current && K(Pe, e, { originalEvent: a }, {
        discrete: !1
      });
    };
    return r.addEventListener("focusin", o), () => r.removeEventListener("focusin", o);
  }, [r, e]), {
    onFocusCapture: () => n.current = !0,
    onBlurCapture: () => n.current = !1
  };
}
function j() {
  const t = new CustomEvent(M);
  document.dispatchEvent(t);
}
function K(t, r, e, { discrete: n }) {
  const o = e.originalEvent.target, a = new CustomEvent(t, { bubbles: !1, cancelable: !0, detail: e });
  r && o.addEventListener(t, r, { once: !0 }), n ? de(o, a) : o.dispatchEvent(a);
}
var Le = "Portal", J = s.forwardRef((t, r) => {
  var d;
  const { container: e, ...n } = t, [o, a] = s.useState(!1);
  le(() => a(!0), []);
  const c = e || o && ((d = globalThis == null ? void 0 : globalThis.document) == null ? void 0 : d.body);
  return c ? be.createPortal(/* @__PURE__ */ f(A.div, { ...n, ref: r }), c) : null;
});
J.displayName = Le;
var [N, lt] = ue("Tooltip", [
  V
]), k = V(), Q = "TooltipProvider", _e = 700, H = "tooltip.open", [Ae, F] = N(Q), Z = (t) => {
  const {
    __scopeTooltip: r,
    delayDuration: e = _e,
    skipDelayDuration: n = 300,
    disableHoverableContent: o = !1,
    children: a
  } = t, [c, d] = s.useState(!0), l = s.useRef(!1), u = s.useRef(0);
  return s.useEffect(() => {
    const i = u.current;
    return () => window.clearTimeout(i);
  }, []), /* @__PURE__ */ f(
    Ae,
    {
      scope: r,
      isOpenDelayed: c,
      delayDuration: e,
      onOpen: s.useCallback(() => {
        window.clearTimeout(u.current), d(!1);
      }, []),
      onClose: s.useCallback(() => {
        window.clearTimeout(u.current), u.current = window.setTimeout(
          () => d(!0),
          n
        );
      }, [n]),
      isPointerInTransitRef: l,
      onPointerInTransitChange: s.useCallback((i) => {
        l.current = i;
      }, []),
      disableHoverableContent: o,
      children: a
    }
  );
};
Z.displayName = Q;
var S = "Tooltip", [Ne, O] = N(S), ee = (t) => {
  const {
    __scopeTooltip: r,
    children: e,
    open: n,
    defaultOpen: o = !1,
    onOpenChange: a,
    disableHoverableContent: c,
    delayDuration: d
  } = t, l = F(S, t.__scopeTooltip), u = k(r), [i, v] = s.useState(null), h = Ee(), y = s.useRef(0), m = c ?? l.disableHoverableContent, E = d ?? l.delayDuration, b = s.useRef(!1), [C = !1, T] = ie({
    prop: n,
    defaultProp: o,
    onChange: (p) => {
      p ? (l.onOpen(), document.dispatchEvent(new CustomEvent(H))) : l.onClose(), a == null || a(p);
    }
  }), P = s.useMemo(() => C ? b.current ? "delayed-open" : "instant-open" : "closed", [C]), g = s.useCallback(() => {
    window.clearTimeout(y.current), b.current = !1, T(!0);
  }, [T]), w = s.useCallback(() => {
    window.clearTimeout(y.current), T(!1);
  }, [T]), D = s.useCallback(() => {
    window.clearTimeout(y.current), y.current = window.setTimeout(() => {
      b.current = !0, T(!0);
    }, E);
  }, [E, T]);
  return s.useEffect(() => () => window.clearTimeout(y.current), []), /* @__PURE__ */ f(me, { ...u, children: /* @__PURE__ */ f(
    Ne,
    {
      scope: r,
      contentId: h,
      open: C,
      stateAttribute: P,
      trigger: i,
      onTriggerChange: v,
      onTriggerEnter: s.useCallback(() => {
        l.isOpenDelayed ? D() : g();
      }, [l.isOpenDelayed, D, g]),
      onTriggerLeave: s.useCallback(() => {
        m ? w() : window.clearTimeout(y.current);
      }, [w, m]),
      onOpen: g,
      onClose: w,
      disableHoverableContent: m,
      children: e
    }
  ) });
};
ee.displayName = S;
var B = "TooltipTrigger", te = s.forwardRef(
  (t, r) => {
    const { __scopeTooltip: e, ...n } = t, o = O(B, e), a = F(B, e), c = k(e), d = s.useRef(null), l = _(r, d, o.onTriggerChange), u = s.useRef(!1), i = s.useRef(!1), v = s.useCallback(() => u.current = !1, []);
    return s.useEffect(() => () => document.removeEventListener("pointerup", v), [v]), /* @__PURE__ */ f(pe, { asChild: !0, ...c, children: /* @__PURE__ */ f(
      A.button,
      {
        "aria-describedby": o.open ? o.contentId : void 0,
        "data-state": o.stateAttribute,
        ...n,
        ref: l,
        onPointerMove: x(t.onPointerMove, (h) => {
          h.pointerType !== "touch" && !i.current && !a.isPointerInTransitRef.current && (o.onTriggerEnter(), i.current = !0);
        }),
        onPointerLeave: x(t.onPointerLeave, () => {
          o.onTriggerLeave(), i.current = !1;
        }),
        onPointerDown: x(t.onPointerDown, () => {
          u.current = !0, document.addEventListener("pointerup", v, { once: !0 });
        }),
        onFocus: x(t.onFocus, () => {
          u.current || o.onOpen();
        }),
        onBlur: x(t.onBlur, o.onClose),
        onClick: x(t.onClick, o.onClose)
      }
    ) });
  }
);
te.displayName = B;
var W = "TooltipPortal", [ke, Se] = N(W, {
  forceMount: void 0
}), re = (t) => {
  const { __scopeTooltip: r, forceMount: e, children: n, container: o } = t, a = O(W, r);
  return /* @__PURE__ */ f(ke, { scope: r, forceMount: e, children: /* @__PURE__ */ f(X, { present: e || a.open, children: /* @__PURE__ */ f(J, { asChild: !0, container: o, children: n }) }) });
};
re.displayName = W;
var R = "TooltipContent", ne = s.forwardRef(
  (t, r) => {
    const e = Se(R, t.__scopeTooltip), { forceMount: n = e.forceMount, side: o = "top", ...a } = t, c = O(R, t.__scopeTooltip);
    return /* @__PURE__ */ f(X, { present: n || c.open, children: c.disableHoverableContent ? /* @__PURE__ */ f(oe, { side: o, ...a, ref: r }) : /* @__PURE__ */ f(Ie, { side: o, ...a, ref: r }) });
  }
), Ie = s.forwardRef((t, r) => {
  const e = O(R, t.__scopeTooltip), n = F(R, t.__scopeTooltip), o = s.useRef(null), a = _(r, o), [c, d] = s.useState(null), { trigger: l, onClose: u } = e, i = o.current, { onPointerInTransitChange: v } = n, h = s.useCallback(() => {
    d(null), v(!1);
  }, [v]), y = s.useCallback(
    (m, E) => {
      const b = m.currentTarget, C = { x: m.clientX, y: m.clientY }, T = Fe(C, b.getBoundingClientRect()), P = We(C, T), g = Ue(E.getBoundingClientRect()), w = je([...P, ...g]);
      d(w), v(!0);
    },
    [v]
  );
  return s.useEffect(() => () => h(), [h]), s.useEffect(() => {
    if (l && i) {
      const m = (b) => y(b, i), E = (b) => y(b, l);
      return l.addEventListener("pointerleave", m), i.addEventListener("pointerleave", E), () => {
        l.removeEventListener("pointerleave", m), i.removeEventListener("pointerleave", E);
      };
    }
  }, [l, i, y, h]), s.useEffect(() => {
    if (c) {
      const m = (E) => {
        const b = E.target, C = { x: E.clientX, y: E.clientY }, T = (l == null ? void 0 : l.contains(b)) || (i == null ? void 0 : i.contains(b)), P = !Ge(C, c);
        T ? h() : P && (h(), u());
      };
      return document.addEventListener("pointermove", m), () => document.removeEventListener("pointermove", m);
    }
  }, [l, i, c, u, h]), /* @__PURE__ */ f(oe, { ...t, ref: a });
}), [Me, He] = N(S, { isInside: !1 }), oe = s.forwardRef(
  (t, r) => {
    const {
      __scopeTooltip: e,
      children: n,
      "aria-label": o,
      onEscapeKeyDown: a,
      onPointerDownOutside: c,
      ...d
    } = t, l = O(R, e), u = k(e), { onClose: i } = l;
    return s.useEffect(() => (document.addEventListener(H, i), () => document.removeEventListener(H, i)), [i]), s.useEffect(() => {
      if (l.trigger) {
        const v = (h) => {
          const y = h.target;
          y != null && y.contains(l.trigger) && i();
        };
        return window.addEventListener("scroll", v, { capture: !0 }), () => window.removeEventListener("scroll", v, { capture: !0 });
      }
    }, [l.trigger, i]), /* @__PURE__ */ f(
      q,
      {
        asChild: !0,
        disableOutsidePointerEvents: !1,
        onEscapeKeyDown: a,
        onPointerDownOutside: c,
        onFocusOutside: (v) => v.preventDefault(),
        onDismiss: i,
        children: /* @__PURE__ */ z(
          ve,
          {
            "data-state": l.stateAttribute,
            ...u,
            ...d,
            ref: r,
            style: {
              ...d.style,
              "--radix-tooltip-content-transform-origin": "var(--radix-popper-transform-origin)",
              "--radix-tooltip-content-available-width": "var(--radix-popper-available-width)",
              "--radix-tooltip-content-available-height": "var(--radix-popper-available-height)",
              "--radix-tooltip-trigger-width": "var(--radix-popper-anchor-width)",
              "--radix-tooltip-trigger-height": "var(--radix-popper-anchor-height)"
            },
            children: [
              /* @__PURE__ */ f(ce, { children: n }),
              /* @__PURE__ */ f(Me, { scope: e, isInside: !0, children: /* @__PURE__ */ f(he, { id: l.contentId, role: "tooltip", children: o || n }) })
            ]
          }
        )
      }
    );
  }
);
ne.displayName = R;
var se = "TooltipArrow", Be = s.forwardRef(
  (t, r) => {
    const { __scopeTooltip: e, ...n } = t, o = k(e);
    return He(
      se,
      e
    ).isInside ? null : /* @__PURE__ */ f(ye, { ...o, ...n, ref: r });
  }
);
Be.displayName = se;
function Fe(t, r) {
  const e = Math.abs(r.top - t.y), n = Math.abs(r.bottom - t.y), o = Math.abs(r.right - t.x), a = Math.abs(r.left - t.x);
  switch (Math.min(e, n, o, a)) {
    case a:
      return "left";
    case o:
      return "right";
    case e:
      return "top";
    case n:
      return "bottom";
    default:
      throw new Error("unreachable");
  }
}
function We(t, r, e = 5) {
  const n = [];
  switch (r) {
    case "top":
      n.push(
        { x: t.x - e, y: t.y + e },
        { x: t.x + e, y: t.y + e }
      );
      break;
    case "bottom":
      n.push(
        { x: t.x - e, y: t.y - e },
        { x: t.x + e, y: t.y - e }
      );
      break;
    case "left":
      n.push(
        { x: t.x + e, y: t.y - e },
        { x: t.x + e, y: t.y + e }
      );
      break;
    case "right":
      n.push(
        { x: t.x - e, y: t.y - e },
        { x: t.x - e, y: t.y + e }
      );
      break;
  }
  return n;
}
function Ue(t) {
  const { top: r, right: e, bottom: n, left: o } = t;
  return [
    { x: o, y: r },
    { x: e, y: r },
    { x: e, y: n },
    { x: o, y: n }
  ];
}
function Ge(t, r) {
  const { x: e, y: n } = t;
  let o = !1;
  for (let a = 0, c = r.length - 1; a < r.length; c = a++) {
    const d = r[a].x, l = r[a].y, u = r[c].x, i = r[c].y;
    l > n != i > n && e < (u - d) * (n - l) / (i - l) + d && (o = !o);
  }
  return o;
}
function je(t) {
  const r = t.slice();
  return r.sort((e, n) => e.x < n.x ? -1 : e.x > n.x ? 1 : e.y < n.y ? -1 : e.y > n.y ? 1 : 0), ze(r);
}
function ze(t) {
  if (t.length <= 1) return t.slice();
  const r = [];
  for (let n = 0; n < t.length; n++) {
    const o = t[n];
    for (; r.length >= 2; ) {
      const a = r[r.length - 1], c = r[r.length - 2];
      if ((a.x - c.x) * (o.y - c.y) >= (a.y - c.y) * (o.x - c.x)) r.pop();
      else break;
    }
    r.push(o);
  }
  r.pop();
  const e = [];
  for (let n = t.length - 1; n >= 0; n--) {
    const o = t[n];
    for (; e.length >= 2; ) {
      const a = e[e.length - 1], c = e[e.length - 2];
      if ((a.x - c.x) * (o.y - c.y) >= (a.y - c.y) * (o.x - c.x)) e.pop();
      else break;
    }
    e.push(o);
  }
  return e.pop(), r.length === 1 && e.length === 1 && r[0].x === e[0].x && r[0].y === e[0].y ? r : r.concat(e);
}
var Ye = Z, Ve = ee, Xe = te, $e = re, qe = ne;
const Ke = ae(
  ({
    children: t,
    className: r,
    content: e,
    defaultOpen: n,
    open: o,
    onClick: a,
    onOpenChange: c,
    side: d,
    asChild: l = !1,
    align: u = "center",
    ...i
  }, v) => /* @__PURE__ */ f(Ye, { delayDuration: 150, children: /* @__PURE__ */ z(
    Ve,
    {
      open: o,
      defaultOpen: n,
      onOpenChange: c,
      children: [
        /* @__PURE__ */ f(
          Xe,
          {
            className: Ce,
            onClick: a,
            asChild: l,
            children: t
          }
        ),
        /* @__PURE__ */ f($e, { children: /* @__PURE__ */ f(
          qe,
          {
            ref: v,
            side: d,
            sideOffset: 4,
            align: u,
            className: Te(
              // base
              "min-w-s-6 max-w-[calc(var(--fui-baseline)*8+var(--fui-subline)*2)] rounded-xs bg-el-bg-tooltip px-sp-2 py-sp-1 text-center text-t3 text-text-primary-inverted",
              // transition
              "data-[side=top]:animate-slide-up-and-fade data-[state=closed]:animate-hide",
              r
            ),
            ...i,
            children: e
          }
        ) })
      ]
    }
  ) })
);
Ke.displayName = "Tooltip";
export {
  Ke as Tooltip
};
