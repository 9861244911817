import { jsx as o } from "react/jsx-runtime";
import { forwardRef as r } from "react";
import { S as c } from "../../index-CCavb-1K.js";
import { c as i } from "../../index-BwYCDOHZ.js";
import { cn as p } from "../../utils/cn.js";
const m = i(
  "inline-flex h-s-4 items-center justify-center gap-sp-1 whitespace-nowrap rounded-full px-sp-2 text-t3-style-2",
  {
    variants: {
      variant: {
        danger: "bg-tag-bg-danger text-tag-content-danger",
        warning: "bg-tag-bg-warning text-tag-content-warning",
        success: "bg-tag-bg-success text-tag-content-success",
        status: "bg-tag-bg-status text-tag-content-status",
        pending: "bg-tag-bg-1 text-tag-content-1",
        promotion1: "bg-tag-bg-2 text-tag-content-2",
        promotion2: "bg-tag-bg-3 text-tag-content-3"
      }
    }
  }
), b = r(
  ({ children: t, className: n, variant: a = "status", asChild: e, ...g }, s) => /* @__PURE__ */ o(
    e ? c : "span",
    {
      className: p(m({ variant: a, className: n })),
      ...g,
      ref: s,
      children: t
    }
  )
);
b.displayName = "Tag";
export {
  b as Tag
};
