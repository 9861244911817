import { jsxs as s, jsx as e } from "react/jsx-runtime";
import { forwardRef as u, useState as C } from "react";
import { cn as n } from "../../utils/cn.js";
import { focusRing as h } from "../../utils/focus-ring.js";
const v = u(
  ({ className: o, hasError: c, type: r, ...d }, l) => {
    const [t, p] = C(r), i = r === "search", a = r === "password";
    return /* @__PURE__ */ s("div", { className: "relative", children: [
      /* @__PURE__ */ e(
        "input",
        {
          type: a ? t : r,
          className: n(
            // Base
            "relative h-s-6 w-full appearance-none rounded-sm border bg-transparent pl-sp-4 pr-sp-3 text-t1 outline-none transition",
            // Border colors
            "border-action-stroke-input-primary-default hover:border-action-stroke-input-primary-hover focus:border-action-stroke-input-primary-active",
            // Disabled
            "disabled:border-action-stroke-input-primary-disabled disabled:text-action-content-primary-disabled",
            // Error
            {
              "border-action-stroke-input-danger-active hover:border-action-stroke-input-danger-active focus:border-action-stroke-input-danger-active": c
            },
            {
              // Padding for search icon
              "pl-[calc(var(--fui-sp-3)+var(--fui-s-3)+var(--fui-sp-2))]": i
            },
            {
              // Padding for password icon
              "pr-[calc(var(--fui-sp-3)+var(--fui-s-3)+var(--fui-sp-2))]": a
            },
            // Add custom xmark button when type is set to "search"
            "[&::-webkit-search-cancel-button]:size-s-3 [&::-webkit-search-cancel-button]:appearance-none [&::-webkit-search-cancel-button]:bg-search-input",
            o
          ),
          ref: l,
          ...d
        }
      ),
      i && /* @__PURE__ */ e("div", { className: "pointer-events-none absolute left-sp-3 top-1/2 -translate-y-1/2", children: /* @__PURE__ */ e(
        "svg",
        {
          xmlns: "http://www.w3.org/2000/svg",
          width: "20",
          height: "20",
          viewBox: "0 0 20 20",
          fill: "none",
          "aria-hidden": "true",
          children: /* @__PURE__ */ e(
            "path",
            {
              d: "M14.5962 14.5962L17.5 17.5M16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10Z",
              strokeWidth: "1.5",
              strokeLinecap: "round",
              className: "stroke-current"
            }
          )
        }
      ) }),
      a && /* @__PURE__ */ e("div", { className: "absolute right-sp-3 top-1/2 flex -translate-y-1/2 items-center justify-center", children: /* @__PURE__ */ s(
        "button",
        {
          className: n("size-fit rounded-xs", h),
          onClick: () => p(t === "password" ? "text" : "password"),
          children: [
            /* @__PURE__ */ e("span", { className: "sr-only", children: t === "password" ? "Show password" : "Hide password" }),
            t === "password" ? /* @__PURE__ */ s(
              "svg",
              {
                xmlns: "http://www.w3.org/2000/svg",
                width: "21",
                height: "20",
                viewBox: "0 0 21 20",
                fill: "none",
                "aria-hidden": "true",
                children: [
                  /* @__PURE__ */ e(
                    "path",
                    {
                      d: "M1.55584 10.6309C1.35467 10.3718 1.20093 10.1547 1.09596 10C1.20093 9.84526 1.35467 9.62825 1.55584 9.36907C1.9779 8.82528 2.60579 8.09962 3.42657 7.37478C5.07127 5.92232 7.45821 4.5 10.5 4.5C13.5418 4.5 15.9287 5.92232 17.5734 7.37478C18.3942 8.09962 19.0221 8.82528 19.4442 9.36907C19.6453 9.62825 19.7991 9.84527 19.904 10C19.7991 10.1547 19.6453 10.3718 19.4442 10.6309C19.0221 11.1747 18.3942 11.9004 17.5734 12.6252C15.9287 14.0777 13.5418 15.5 10.5 15.5C7.45821 15.5 5.07127 14.0777 3.42657 12.6252C2.60579 11.9004 1.9779 11.1747 1.55584 10.6309Z",
                      className: "stroke-current"
                    }
                  ),
                  /* @__PURE__ */ e(
                    "circle",
                    {
                      cx: "10.5",
                      cy: "10",
                      r: "2.5",
                      className: "stroke-current"
                    }
                  )
                ]
              }
            ) : /* @__PURE__ */ s(
              "svg",
              {
                xmlns: "http://www.w3.org/2000/svg",
                width: "21",
                height: "20",
                viewBox: "0 0 21 20",
                fill: "none",
                "aria-hidden": "true",
                children: [
                  /* @__PURE__ */ e(
                    "path",
                    {
                      d: "M3 2.5L18 17.5",
                      className: "stroke-current",
                      strokeLinecap: "round"
                    }
                  ),
                  /* @__PURE__ */ e(
                    "path",
                    {
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                      d: "M16.5532 14.0532C19.1239 12.3011 20.5 10 20.5 10C20.5 10 16.9117 4 10.5 4C9.25525 4 8.11692 4.22613 7.0906 4.5906L7.88344 5.38344C8.68977 5.1426 9.56287 5 10.5 5C13.3777 5 15.6516 6.34464 17.2425 7.74955C18.0348 8.44924 18.6416 9.15055 19.0492 9.67564C19.1409 9.79376 19.2221 9.90258 19.2929 10C19.2221 10.0974 19.1409 10.2062 19.0492 10.3244C18.6416 10.8494 18.0348 11.5508 17.2425 12.2504C16.8201 12.6234 16.3497 12.9921 15.8326 13.3326L16.5532 14.0532ZM5.16744 6.66744C4.65033 7.00788 4.17987 7.37659 3.75754 7.74955C2.96525 8.44924 2.35837 9.15055 1.95083 9.67564C1.85915 9.79376 1.77785 9.90258 1.70709 10C1.77785 10.0974 1.85915 10.2062 1.95083 10.3244C2.35837 10.8494 2.96525 11.5508 3.75754 12.2504C5.3484 13.6554 7.62228 15 10.5 15C11.4371 15 12.3102 14.8574 13.1166 14.6166L13.9094 15.4094C12.8831 15.7739 11.7447 16 10.5 16C4.08826 16 0.5 10 0.5 10C0.5 10 1.87615 7.69892 4.4468 5.9468L5.16744 6.66744Z",
                      className: "fill-current"
                    }
                  ),
                  /* @__PURE__ */ e(
                    "path",
                    {
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                      d: "M13.3715 10.8715C13.4551 10.5957 13.5 10.3031 13.5 10C13.5 8.34315 12.1569 7 10.5 7C10.1969 7 9.90432 7.04495 9.62854 7.12854L10.5 8C11.6046 8 12.5 8.89543 12.5 10L13.3715 10.8715ZM8.5 10L7.62854 9.12854C7.54495 9.40432 7.5 9.69691 7.5 10C7.5 11.6569 8.84315 13 10.5 13C10.8031 13 11.0957 12.9551 11.3715 12.8715L10.5 12C9.39543 12 8.5 11.1046 8.5 10Z",
                      className: "fill-current"
                    }
                  )
                ]
              }
            )
          ]
        }
      ) })
    ] });
  }
);
v.displayName = "Input";
export {
  v as Input
};
