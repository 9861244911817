import { SessionProvider } from "@flare/next-auth/react";

import { AnonymousSession } from "./anonymous-session";

/**
 * AuthProvider is a wrapper component that provides the authentication context to its children.
 *
 * It uses the `SessionProvider` from `next-auth/react` to provide the session context.
 */
export function AuthProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      {/* Currnetly disbaled due to product decision */}
      {/* TODO: Move it to _document.tsx, this script needs to be loaded first */}
      {/* <Script
        src="https://accounts.google.com/gsi/client"
        strategy="beforeInteractive"
      /> */}
      <SessionProvider>
        <AnonymousSession>{children}</AnonymousSession>
      </SessionProvider>
    </>
  );
}
